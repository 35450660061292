import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { LoginHistoryTotalStatsSectionIds } from 'routes/Dashboard/LoginHistoryTotalStats/types'
import { SECTION_TITLE_BY_ID, TOTAL_STATS_SECTIONS } from 'routes/Dashboard/LoginHistoryTotalStats/constants'
import { TotalStats } from 'components/common/TotalStats'
import {
  getLoginHistoryUniqueUserLogins,
  getNewUsersByRange
} from 'redux/selectors/login-history'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'

export const LoginHistoryTotalStats: React.FC = () => {
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchLoginHistory))

  const uniqueLoginsCount30days = useSelector((state: RootState) =>
    getLoginHistoryUniqueUserLogins(state, 30))
  const uniqueLoginsCount90days = useSelector((state: RootState) =>
    getLoginHistoryUniqueUserLogins(state, 90))

  const newUsersCountLast30days = useSelector((state: RootState) =>
    getNewUsersByRange(state, 30))
  const newUsersCountLast90days = useSelector((state: RootState) =>
    getNewUsersByRange(state, 90))

  const sectionValueGetter = useCallback((id: string) => {
    switch (id) {
      case LoginHistoryTotalStatsSectionIds.UniqueLoginsLast30days:
        return uniqueLoginsCount30days
      case LoginHistoryTotalStatsSectionIds.UniqueLoginsLast90days:
        return uniqueLoginsCount90days
      case LoginHistoryTotalStatsSectionIds.NewUsersLast30days:
        return newUsersCountLast30days
      case LoginHistoryTotalStatsSectionIds.NewUsersLast90days:
        return newUsersCountLast90days
      default:
        return null
    }
  }, [
    uniqueLoginsCount30days,
    uniqueLoginsCount90days,
    newUsersCountLast30days,
    newUsersCountLast90days
  ])

  const sectionTitleGetter = useCallback((id: LoginHistoryTotalStatsSectionIds) =>
      SECTION_TITLE_BY_ID[id]
    , [])

  return (
    <TotalStats
      title="Total Stats"
      className={styles.wrapper}
      isLoading={isLoading}
      sections={TOTAL_STATS_SECTIONS}
      sectionValueGetter={sectionValueGetter}
      sectionTitleGetter={sectionTitleGetter}
    />
  )
}