import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Button, Typography } from 'antd'
import { RoleSelect } from 'components/roles/RoleSelect'
import { getEditedUserByRole } from 'redux/selectors/users'
import { getIsLoading } from 'redux/selectors/loaders'
import {
  confirmEditUserByRole,
  stopEditUserByRole
} from 'redux/actions/users'
import styles from 'components/modals/EditUserByRole/styles.module.css'
import { useIntl } from 'react-intl'

const { Text } = Typography

export const EditUserByRoleModal: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const user = useSelector(getEditedUserByRole)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.ConfirmEditUserByRole)
  )
  const [selectedUserRoleId, setSelectedUserRoleId] =
    useState<number>()
  const handleConfirmEdit = useCallback(() => {
    if (selectedUserRoleId) {
      dispatch(confirmEditUserByRole({ roleId: selectedUserRoleId }))
    }
  }, [selectedUserRoleId, dispatch])

  useEffect(() => {
    if (user) {
      const { roles } = user
      const [role] = roles
      const {
        role: { id }
      } = role

      setSelectedUserRoleId(id)
    }

    return () => {
      // Clears edited user id before unmount
      dispatch(stopEditUserByRole())
    }
  }, [user, dispatch])

  if (!user) {
    return null
  }

  const { email, roles } = user
  const [role] = roles
  const {
    role: { id }
  } = role
  const isSubmitDisabled =
    selectedUserRoleId === id || !selectedUserRoleId || isLoading

  return (
    <div className={styles.wrapper}>
      <Text className={styles.email}>{email}</Text>
      <RoleSelect
        value={selectedUserRoleId}
        onSelect={setSelectedUserRoleId}
      />
      <Button
        type="primary"
        loading={isLoading}
        onClick={handleConfirmEdit}
        disabled={isSubmitDisabled}
        className={styles.submit}
      >
        {formatMessage({
          id: 'component.modals.edit-user-by-role.label.edit'
        })}
      </Button>
    </div>
  )
}
