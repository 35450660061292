import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes as RouterRoutes
} from 'react-router-dom'
import { Routes as RoutePaths } from 'routes/constants'
import { Routes } from 'routes/constants'
import { SecureRoute } from 'routes/SecureRoute'
import { Index } from 'routes/Index'
import { Dashboard } from 'routes/Dashboard'
import { Permissions } from 'routes/Permissions'
import { Profile } from 'routes/Profile'
import { Unauthorized } from 'routes/Unauthorized'
import { PermissionRequestsPage } from 'routes/PermissionRequests'

interface Props {}

const App: React.FC<Props> = () => (
  <BrowserRouter>
    <RouterRoutes>
      <Route
        path={Routes.Index}
        element={
          <SecureRoute>
            <Index />
          </SecureRoute>
        }
      >
        <Route
          index
          element={<Dashboard />}
        />

        <Route
          path={RoutePaths.Profile}
          element={<Profile />}
        />

        <Route
          path={RoutePaths.Permissions}
          element={<Permissions />}
        />

        <Route
          path={RoutePaths.PermissionRequests}
          element={<PermissionRequestsPage />}
        />

        <Route
          path={RoutePaths.Unauthorized}
          element={<Unauthorized />}
        />
      </Route>
    </RouterRoutes>
  </BrowserRouter>
)

export default App
