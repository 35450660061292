import React from 'react'
import { Typography } from 'antd'
import classNames from 'classnames'
import styles from './styles.module.css'

const { Text } = Typography

export interface Props {
  text: string
  className?: string
}

export const HighlightedText: React.FC<Props> = ({
  text,
  className
}) => {
  return (
    <Text className={classNames(styles.wrapper, className)}>
      {text}
    </Text>
  )
}