import { createAction } from '@reduxjs/toolkit'
import {
  FETCH_USERS,
  FETCH_USERS_BY_ROLE,
  CONSUME_USERS,
  CONSUME_USERS_BY_ROLE,
  ADD_USER_BY_ROLE,
  EDIT_USER_BY_ROLE,
  DELETE_USER_BY_ROLE,
  STOP_EDIT_USER_BY_ROLE,
  CONFIRM_EDIT_USER_BY_ROLE,
  STOP_DELETE_USER_BY_ROLE,
  CONFIRM_DELETE_USER_BY_ROLE,
  SET_SEARCH
} from 'redux/actions/users/constants'
import {
  ConsumeUsersPayload,
  ConsumeUsersByRolePayload,
  AddUserByRolePayload,
  EditUserByRolePayload,
  ConfirmEditUserByRolePayload,
  DeleteUserByRolePayload,
  SetSearchTextPayload
} from 'redux/actions/users/types'

export const fetchUsers = createAction(FETCH_USERS)

export const fetchUsersByRole = createAction(FETCH_USERS_BY_ROLE)

export const consumeUsers =
  createAction<ConsumeUsersPayload>(CONSUME_USERS)

export const consumeUsersByRole =
  createAction<ConsumeUsersByRolePayload>(CONSUME_USERS_BY_ROLE)

export const addUserByRole =
  createAction<AddUserByRolePayload>(ADD_USER_BY_ROLE)

export const editUserByRole =
  createAction<EditUserByRolePayload>(EDIT_USER_BY_ROLE)

export const deleteUserByRole = createAction<DeleteUserByRolePayload>(
  DELETE_USER_BY_ROLE
)

export const confirmEditUserByRole =
  createAction<ConfirmEditUserByRolePayload>(
    CONFIRM_EDIT_USER_BY_ROLE
  )

export const confirmDeleteUserByRole = createAction(
  CONFIRM_DELETE_USER_BY_ROLE
)

export const stopEditUserByRole = createAction(STOP_EDIT_USER_BY_ROLE)

export const stopDeleteUserByRole = createAction(
  STOP_DELETE_USER_BY_ROLE
)

export const setSearchText =
  createAction<SetSearchTextPayload>(SET_SEARCH)
