import { createAction } from '@reduxjs/toolkit'
import {
  CLEAR_ROLES,
  FETCH_ROLES,
  CONSUME_ROLES,
} from 'redux/actions/roles/constants'
import { ConsumeRolesPayload } from 'redux/actions/roles/types'

export const fetchRoles = createAction(FETCH_ROLES)

export const consumeRoles = createAction<ConsumeRolesPayload>(CONSUME_ROLES)

export const clearRoles = createAction(CLEAR_ROLES)