export enum RoleNames {
  Admin = 'Admin',
  User = 'User'
}

export interface RoleType {
  system: number
  role: {
    id: number
    name: RoleNames
  }
}

export interface RolesStateType {
  roles: RoleType[] | null
}