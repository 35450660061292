import {
  ApiRouteNames,
  ApiRoutePaths,
  ApiRoutesType,
  HttpContentTypeHeaderValues,
  HTTPHeadersType,
  HTTPMethods
} from 'api/types'

const ApiRoutes: ApiRoutesType = {
  [ApiRouteNames.Account]: {
    url: ApiRoutePaths.Account,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.Roles]: {
    url: ApiRoutePaths.Roles,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.Systems]: {
    url: ApiRoutePaths.Systems,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.Users]: {
    url: ApiRoutePaths.Users,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.AddUserByRole]: {
    url: ApiRoutePaths.UsersByRole,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.EditUserByRole]: {
    url: ApiRoutePaths.UsersByRole,
    method: HTTPMethods.PUT,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.DeleteUserByRole]: {
    url: ApiRoutePaths.UsersByRole,
    method: HTTPMethods.DELETE,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.UsersByRole]: {
    url: ApiRoutePaths.UsersByRole,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.FetchPermissions]: {
    url: ApiRoutePaths.Permissions,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.AddPermission]: {
    url: ApiRoutePaths.Permissions,
    method: HTTPMethods.POST,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.FetchPermissionRequests]: {
    url: ApiRoutePaths.PermissionRequests,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.FetchPermissionOperations]: {
    url: ApiRoutePaths.PermissionOperations,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.UpdatePermissionRequests]: {
    url: ApiRoutePaths.PermissionRequests,
    method: HTTPMethods.PUT,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
  [ApiRouteNames.LoginHistory]: {
    url: ApiRoutePaths.LoginHistory,
    method: HTTPMethods.GET,
    headers: {
      [HTTPHeadersType.ContentType]: HttpContentTypeHeaderValues.ApplicationJSON,
    }
  },
}

export default ApiRoutes
