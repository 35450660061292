import {
  all,
  call,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import {
  ADD_USER_BY_ROLE,
  CONFIRM_DELETE_USER_BY_ROLE,
  CONFIRM_EDIT_USER_BY_ROLE,
  DELETE_USER_BY_ROLE,
  EDIT_USER_BY_ROLE,
  FETCH_USERS,
  FETCH_USERS_BY_ROLE
} from 'redux/actions/users/constants'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  AddUserByRolePayload,
  ConfirmEditUserByRolePayload
} from 'redux/actions/users/types'
import { getSelectedSystemId } from 'redux/selectors/systems'
import {
  consumeUsers,
  consumeUsersByRole,
  fetchUsersByRole
} from 'redux/actions/users'
import { closeModal, openModal } from 'redux/actions/modals'
import { ModalsType } from 'redux/reducers/modals/types'
import {
  getDeletedUserId,
  getEditedUserByRoleId
} from 'redux/selectors/users'
import { handleError } from 'redux/actions/errors'

function* handleFetchUsers() {
  yield put(startLoading(Loaders.FetchUsers))

  try {
    const { data } = yield call(api.users)

    yield put(consumeUsers({ users: data }))
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchUsers))
  }
}

function* handleFetchUsersByRole() {
  const selectedSystemId: number | null = yield select(
    getSelectedSystemId
  )
  yield put(startLoading(Loaders.FetchUsersByRole))

  try {
    const { data } = yield call(api.usersByRole, {
      queryParams: { system: selectedSystemId }
    })

    yield put(consumeUsersByRole({ usersByRole: data }))
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchUsersByRole))
  }
}

function* handleAddUserByRole({
  payload: { id, roleId }
}: PayloadAction<AddUserByRolePayload>) {
  const selectedSystemId: number | null = yield select(
    getSelectedSystemId
  )

  yield put(startLoading(Loaders.AddUserByRole))

  try {
    yield call(api.addUserByRole, {
      body: {
        system: selectedSystemId,
        user: id,
        role: roleId
      }
    })

    yield put(closeModal({ id: ModalsType.AddUserByRole }))
    yield put(fetchUsersByRole())
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.AddUserByRole))
  }
}

// Edit user by role
function* handleEditUserByRole() {
  yield put(openModal({ id: ModalsType.EditUserByRole }))
}

function* handleConfirmEditUserByRole({
  payload: { roleId }
}: PayloadAction<ConfirmEditUserByRolePayload>) {
  const selectedSystemId: number = yield select(getSelectedSystemId)
  const userId: number = yield select(getEditedUserByRoleId)

  yield put(startLoading(Loaders.ConfirmEditUserByRole))

  try {
    yield call(api.editUserByRole, {
      body: {
        system: selectedSystemId,
        user: userId,
        role: roleId
      }
    })

    yield put(fetchUsersByRole())
    yield put(closeModal({ id: ModalsType.EditUserByRole }))
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.ConfirmEditUserByRole))
  }
}

// Delete user by role
function* handleDeleteUserByRole() {
  yield put(openModal({ id: ModalsType.DeleteUserByRole }))
}

function* handleConfirmDeleteUserByRole() {
  const selectedSystemId: number = yield select(getSelectedSystemId)
  const userId: number = yield select(getDeletedUserId)

  yield put(startLoading(Loaders.ConfirmDeleteUserByRole))

  try {
    yield call(api.deleteUserByRole, {
      body: {
        system: selectedSystemId,
        user: userId
      }
    })

    yield put(fetchUsersByRole())
    yield put(closeModal({ id: ModalsType.DeleteUserByRole }))
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.ConfirmDeleteUserByRole))
  }
}

export function* users() {
  yield all([
    takeLatest(FETCH_USERS, handleFetchUsers),
    takeLatest(FETCH_USERS_BY_ROLE, handleFetchUsersByRole),
    takeLatest(ADD_USER_BY_ROLE, handleAddUserByRole),
    takeLatest(EDIT_USER_BY_ROLE, handleEditUserByRole),
    takeLatest(
      CONFIRM_EDIT_USER_BY_ROLE,
      handleConfirmEditUserByRole
    ),
    takeLatest(DELETE_USER_BY_ROLE, handleDeleteUserByRole),
    takeLatest(
      CONFIRM_DELETE_USER_BY_ROLE,
      handleConfirmDeleteUserByRole
    )
  ])
}
