export enum UsersByRoleTableColumnType {
  Id = 'id',
  Email = 'email',
  Roles = 'roles',
  LastLogin = 'lastLogin',
  TotalLogins = 'totalLoginsCount',
  LoginsLast30days = 'loginLast30daysCount',
  LoginsLast90days = 'loginLast90daysCount',
  Edit = 'edit',
  Delete = 'delete'
}
