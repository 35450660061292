import {
  all,
  call,
  put,
  takeLatest,
  select
} from 'redux-saga/effects'
import {
  CHECK_SESSION,
  LOG_OUT,
  LOGGED_IN
} from 'redux/actions/auth/constants'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import {
  checkOktaSession,
  requestTokens,
  setTokensInStorage,
  getTokensFromStorage,
  hasTokenExpired
} from 'api/okta-auth'
import {
  navigateToLoginPage,
  navigateToLogoutPage
} from 'utils/router/navigate'
import { fetchAccountInfo } from 'redux/actions/account'
import { loggedIn, loggedOut, logOut } from 'redux/actions/auth'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { fetchSystemsData } from 'redux/actions/systems'
import { handleError } from 'redux/actions/errors'

const getIsLoggedIn = (state: RootState): boolean =>
  state.auth.isLoggedIn

const signIn = function* () {
  try {
    const { tokens } = yield call(requestTokens)

    yield call(setTokensInStorage, tokens)
    yield put(loggedIn())
  } catch (err) {
    yield put(logOut())
  }
}

const verifyTokens = function* () {
  const { idToken, accessToken } = yield call(getTokensFromStorage)

  const isIdTokenExpired: boolean = yield call(
    hasTokenExpired,
    idToken
  )
  const isAccessTokenExpired: boolean = yield call(
    hasTokenExpired,
    accessToken
  )

  if (isIdTokenExpired || isAccessTokenExpired) {
    yield put(logOut())
  }
}

const logIn = function* () {
  const isLoggedIn: boolean = yield select(getIsLoggedIn)

  if (!isLoggedIn) {
    yield call(signIn)
  } else {
    yield call(verifyTokens)
  }
}

const handleLogOut = function* () {
  const hasSession: boolean = yield call(checkOktaSession)
  const navOptions = {
    query: {
      redirect_uri: window.location.href
    }
  }

  if (hasSession) {
    yield call(navigateToLogoutPage, navOptions)
  } else {
    yield put(loggedOut())
    yield call(navigateToLoginPage, navOptions)
  }
}

const handleCheckSession = function* () {
  try {
    yield put(startLoading(Loaders.CheckSession))
    // Add types manually because call effect return type is 'any'
    const hasSession: boolean = yield call(checkOktaSession)

    if (!hasSession) {
      yield call(signIn)
    } else {
      yield call(logIn)
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.CheckSession))
  }
}

const handleLoggedIn = function* () {
  yield put(fetchAccountInfo())
  yield put(fetchSystemsData())
}

export function* auth() {
  yield all([
    takeLatest(CHECK_SESSION, handleCheckSession),
    takeLatest(LOG_OUT, handleLogOut),
    takeLatest(LOGGED_IN, handleLoggedIn)
  ])
}
