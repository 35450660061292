const messages = {
  'component.permission-operations.label.select-operation':
    'Select Operation',
  'component.permission-operations.name.get': 'get',
  'component.permission-operations.name.put': 'put',
  'component.permission-operations.name.post': 'post',
  'component.permission-operations.name.delete': 'delete'
}

export default messages
