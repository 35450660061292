import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Loaders } from 'redux/reducers/loaders/types'
import { Card } from 'components/common/Card'
import { LabelFormatterProps, Table } from 'components/common/Table'
import { RootState } from 'redux/types/store'
import { UsersByRoleTableColumnType } from 'components/users/UsersByRole/types'
import { USERS_TABLE_COLUMNS } from 'components/users/UsersByRole/constants'
import { RoleBadge } from 'components/common/RoleBadge'
import { AddUserButton } from 'components/users/AddUserButton'
import { EditUserButton } from 'components/users/EditUserButton'
import { DeleteUserButton } from 'components/users/DeleteUserButton'
import { UserLoginCount } from 'components/users/UserLoginCount'
import { getIsLoading } from 'redux/selectors/loaders'
import {
  getFilteredUsers,
  getSearchText
} from 'redux/selectors/users'
import { formatDate } from 'utils/date'
import styles from 'components/users/UsersByRole/styles.module.css'
import {
  TableCellDataGetterParams,
  TableCellProps
} from 'react-virtualized'
import { useIntl } from 'react-intl'
import { SearchBar } from 'components/common/SearchBar'
import { UserEmail } from 'components/users/UserEmail'
import { useDispatch } from 'react-redux'
import { setSearchText } from 'redux/actions/users'

export const UsersByRole: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const users = useSelector(getFilteredUsers)
  const searchText = useSelector(getSearchText)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchUsersByRole)
  )

  const cellRenderer = useCallback(
    ({ dataKey, cellData, rowData }: TableCellProps) => {
      if (!rowData) return

      switch (dataKey) {
        case UsersByRoleTableColumnType.Roles:
          return <RoleBadge role={cellData} />
        case UsersByRoleTableColumnType.LastLogin:
          return formatDate(cellData)
        case UsersByRoleTableColumnType.Email:
          return <UserEmail email={cellData} />
        case UsersByRoleTableColumnType.TotalLogins:
        case UsersByRoleTableColumnType.LoginsLast30days:
        case UsersByRoleTableColumnType.LoginsLast90days:
          return <UserLoginCount count={cellData} />
        case UsersByRoleTableColumnType.Edit:
          return <EditUserButton userId={rowData.id} />
        case UsersByRoleTableColumnType.Delete:
          return <DeleteUserButton userId={rowData.id} />
        default:
          return cellData
      }
    },
    []
  )

  const cellDataGetter = useCallback(
    ({ dataKey, rowData }: TableCellDataGetterParams) => {
      if (!rowData) {
        return
      }

      const cellData = rowData[dataKey]

      if (dataKey === UsersByRoleTableColumnType.Roles) {
        const [roleData] = cellData
        const {
          role: { name }
        } = roleData

        return name
      }

      return rowData[dataKey]
    },
    []
  )

  const labelFormatter = useCallback(
    ({ dataKey }: LabelFormatterProps) =>
      formatMessage({
        id: `component.users.users-by-role.table.${dataKey}`
      }),
    [formatMessage]
  )

  const handleSearchChange = useCallback(
    (text: string) => {
      dispatch(setSearchText({ text }))
    },
    [dispatch]
  )

  const handleClearSearch = useCallback(() => {
    dispatch(setSearchText({ text: '' }))
  }, [dispatch])

  const searchBarPlaceholder = formatMessage({
    id: 'component.users.users-by-role.search-bar.placeholder'
  })

  return (
    <Card
      title="Users"
      isLoading={isLoading}
      contentClassName={styles.wrapper}
      extra={<AddUserButton />}
    >
      <SearchBar
        placeholder={searchBarPlaceholder}
        value={searchText}
        onSearchTextChange={handleSearchChange}
        onClearTextClick={handleClearSearch}
        className={styles.search}
      />
      {users && (
        <Table
          rows={users}
          columns={USERS_TABLE_COLUMNS}
          labelFormatter={labelFormatter}
          cellDataGetter={cellDataGetter}
          cellRenderer={cellRenderer}
        />
      )}
    </Card>
  )
}
