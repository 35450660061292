import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { AccountAvatar } from 'components/account/AccountAvatar'
import { MenuContent } from 'components/account/AccountMenu/MenuContent'
import styles from 'components/account/AccountMenu/styles.module.css'




export const AccountMenu: React.FC = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.FetchAccountInfo))

  const showMenu = useCallback(() =>
    setIsMenuVisible(true), [setIsMenuVisible])

  const hideMenu = useCallback(() =>
    setIsMenuVisible(false), [setIsMenuVisible])

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.loading]: isLoading
      })}
      onMouseOver={showMenu}
      onMouseLeave={hideMenu}
    >
      <AccountAvatar isLoading={isLoading}/>
      {isMenuVisible && !isLoading && <MenuContent/>}
    </div>
  )
}

