import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Divider } from 'antd'
import { AccountBaseInfo } from 'components/account/AccountBaseInfo'
import { logOut } from 'redux/actions/auth'
import styles from 'components/account/AccountMenu/MenuContent/styles.module.css'
import { useIntl } from 'react-intl'

export const MenuContent: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleLogOut = useCallback(() => {
    dispatch(logOut())
  }, [dispatch])

  const signOutLabel = formatMessage({
    id: 'component.account-menu.menu-content.label.sign-out'
  })

  return (
    <div className={styles.wrapper}>
      <AccountBaseInfo />
      <Divider />
      <Button onClick={handleLogOut}>{signOutLabel}</Button>
    </div>
  )
}
