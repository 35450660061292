import React from 'react'
import styles from 'components/layout/Header/styles.module.css'
import { AccountMenu } from 'components/account/AccountMenu'
import { Logo } from 'components/layout/Header/Logo'

export const Header: React.FC = () => (
  <div className={styles.wrapper}>
    <Logo/>
    <AccountMenu/>
  </div>
)