import numeral from 'numeral'

export const formatNumberCommaSeparator = (num?: number): string =>
  numeral(num).format('0,0')

export const formatNumberAbbreviate = (num?: number): string =>
  numeral(num).format('0a').toUpperCase()

export const formatNumberAbbreviateFloat = (num?: number): string =>
  numeral(num).format('0.0a').toUpperCase()

export const isNumberAbbreviateFloat = (num: string) =>
  parseInt(num) !== parseFloat(num)

export const formatNumberAbbreviateNonZeroReminder = (
  value: number
): string => {
  const floatFormattedValue = formatNumberAbbreviateFloat(value)
  const intFormattedValue = formatNumberAbbreviate(value)

  return isNumberAbbreviateFloat(floatFormattedValue)
    ? floatFormattedValue
    : intFormattedValue
}

