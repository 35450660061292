import { LoginHistoryTotalStatsSectionIds } from 'routes/Dashboard/LoginHistoryTotalStats/types'

export const TOTAL_STATS_SECTIONS: LoginHistoryTotalStatsSectionIds[] = [
  LoginHistoryTotalStatsSectionIds.UniqueLoginsLast30days,
  LoginHistoryTotalStatsSectionIds.UniqueLoginsLast90days,
  LoginHistoryTotalStatsSectionIds.NewUsersLast30days,
  LoginHistoryTotalStatsSectionIds.NewUsersLast90days,
]

// TODO: move to i18n when react-intl is integrated
// https://nidigitalsolutions.jira.com/browse/CIDI-5752
export const SECTION_TITLE_BY_ID: Record<LoginHistoryTotalStatsSectionIds, string> = {
  [LoginHistoryTotalStatsSectionIds.UniqueLoginsLast30days]: 'Unique user logins last 30 days',
  [LoginHistoryTotalStatsSectionIds.UniqueLoginsLast90days]: 'Unique user logins last 90 days',
  [LoginHistoryTotalStatsSectionIds.NewUsersLast30days]: 'New users in last 30 days',
  [LoginHistoryTotalStatsSectionIds.NewUsersLast90days]: 'New users in last 90 days'
}