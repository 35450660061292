import React, { useCallback } from 'react'
import { Input } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const { Search } = Input

export interface Props {
  value: string
  placeholder?: string
  autoFocus?: boolean
  className?: string
  onSearchTextChange: (text: string) => void
  onClearTextClick: () => void
  onSearchSubmit?: () => void
}

export const SearchBar: React.FC<Props> = ({
  value,
  placeholder,
  autoFocus,
  className,
  onSearchTextChange,
  onClearTextClick,
  onSearchSubmit
}) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchTextChange(e.target.value)
    },
    [onSearchTextChange]
  )

  const renderSuffix = value ? (
    <CloseOutlined
      data-testid="clear-text-suffix"
      onClick={onClearTextClick}
    />
  ) : (
    <span />
  )

  return (
    <Search
      autoFocus={autoFocus}
      value={value}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearchSubmit}
      suffix={renderSuffix}
    />
  )
}
