import React, { PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Routes } from 'routes/constants'
import { Loaders } from 'redux/reducers/loaders/types'
import { GlobalLoader } from 'components/common/GlobalLoader'
import { checkSession } from 'redux/actions/auth'
import { getIsLoggedIn } from 'redux/selectors/auth'
import { getIsLoading } from 'redux/selectors/loaders'
import {
  checkPermissionsBySystem,
  getUserPermissions
} from 'redux/selectors/account'
import styles from 'routes/SecureRoute/styles.module.css'

type Props = {}

export const SecureRoute: React.FC<PropsWithChildren<Props>> = ({
  children
}) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const isCheckingSession = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.CheckSession)
  )
  const permissions = useSelector(getUserPermissions)
  const hasPermissions = useSelector(checkPermissionsBySystem)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(checkSession())
  }, [dispatch])

  useEffect(() => {
    if (!permissions || !hasPermissions) {
      navigate(Routes.Unauthorized)
    }
  }, [permissions, hasPermissions, navigate])

  if (!isLoggedIn && isCheckingSession) {
    return <GlobalLoader className={styles.loader} />
  }

  if (!isLoggedIn) {
    return null
  }

  return <>{children}</>
}
