import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { RolesInitialState } from 'redux/reducers/roles/constants'
import { ConsumeRolesPayload } from 'redux/actions/roles/types'

export const roles = createSlice({
  name: ReducersType.Roles,
  initialState: RolesInitialState,
  reducers: {
    consumeRoles: (state, {
      payload: {
        roles
      }
    }: PayloadAction<ConsumeRolesPayload>) => ({
      ...state,
      roles
    }),
    clearRoles: (state) => ({
      ...state,
      roles: RolesInitialState.roles
    }),
  }
})
