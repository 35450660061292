import { createSelector } from 'reselect'
import { RootState } from 'redux/types/store'
import {
  PermissionGroupedItem,
  PermissionItem
} from 'redux/reducers/permissions/types'
import { getSelectedSystem } from 'redux/selectors/systems'
import orderBy from 'lodash/orderBy'

export const getPermissionItems = (state: RootState) => state.permissions.items

export const getPermissionsBySystem = createSelector(
  getSelectedSystem,
  getPermissionItems,
  (selectedSystem, items) => {
    const itemsById = items
      .filter(({ systemName }: PermissionItem) => selectedSystem && systemName === selectedSystem.name)
      .reduce((acc: Record<number, PermissionGroupedItem>, item: PermissionItem) => {
        const { permissionId, operationId, operationName, ...rest } = item

        if (!acc[permissionId]) {
          acc[permissionId] = {
            permissionId,
            operationId,
            operationName,
            ...rest,
            operations: [{
              id: operationId,
              name: operationName
            }]
          }
        } else {
          acc[permissionId].operations.push({
            id: operationId,
            name: operationName
          })
        }

        return acc
      }, {})

    const itemValues: PermissionGroupedItem[] = Object.values(itemsById)

    return itemValues
      .map(({ roles, ...rest }) => ({
      ...rest,
      roles: orderBy(roles, ['name'])
    }))
  }
)

export const getPermissionName = (state: RootState) =>
  state.permissions.permissionName

export const getPermissionLabel = (state: RootState) =>
  state.permissions.permissionLabel

export const getSelectedRoleIds = (state: RootState) =>
  state.permissions.selectedRoleIds

export const getSelectedOperationIds = (state: RootState) =>
  state.permissions.selectedOperationIds