import { RootState } from 'redux/types/store'
import { RoleNames, RoleType } from 'redux/reducers/roles/types'
import { createSelector } from 'reselect'

export const getRoles = (state: RootState): RoleType[] | null =>
  state.roles.roles

export const getAdminRoleId = createSelector(
  getRoles,
  (roles) => {
    if (!roles) {
      return null
    }

    const adminRole = roles.find(({ role: { name } }) => name === RoleNames.Admin)

    return adminRole
      ? adminRole.role.id
      : null
  }
)