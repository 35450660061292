import React from 'react'
import { PageTitle } from 'components/common/PageTitle'
import { UserPermissions } from 'components/account/UserPermissions'

export const Profile: React.FC = () => {
  return (
    <div>
      <PageTitle text='Profile' />
      <UserPermissions />
    </div>
  )
}