import React, { useCallback } from 'react'
import { PermissionOperationItem } from 'redux/reducers/permission-operations/types'
import { OperationBadge } from 'components/common/OperationBadge'

interface Props {
  items: PermissionOperationItem[]
}

export const OperationsList: React.FC<Props> = ({ items }) => {
  const renderItem = useCallback(
    ({ id, name }: PermissionOperationItem) => (
      <OperationBadge
        key={id}
        operation={name}
      />
    ),
    []
  )

  return <>{items.map(renderItem)}</>
}
