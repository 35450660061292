import { TableColumnType } from 'components/common/Table'

export const REQUESTS_TABLE_COLUMNS: TableColumnType[] = [
  {
    id: 'id',
    dataKey: 'id',
    label: 'ID',
    width: 50
  },
  {
    id: 'email',
    dataKey: 'email',
    label: 'Email'
  },
  {
    id: 'roleName',
    dataKey: 'roleName',
    label: 'Role Name'
  },
  {
    id: 'system',
    dataKey: 'system',
    label: 'System'
  },
  {
    id: 'status',
    dataKey: 'status',
    label: 'Status'
  },
  {
    id: 'createdAt',
    dataKey: 'createdAt',
    label: 'Created at'
  },
  {
    id: 'updatedAt',
    dataKey: 'updatedAt',
    label: 'Updated at'
  },
  {
    id: 'actions',
    dataKey: 'actions',
    label: 'Actions'
  }
]