import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { RoleRequestStatus } from 'redux/reducers/permission-requests/types'
import { Button } from 'antd'
import { Loader } from 'components/common/Loader'
import { updatePermissionRequestsStatus } from 'redux/actions/permission-requests'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'
import { useIntl } from 'react-intl'

interface Props {
  requestId: number
}

export const PermissionRequestActions: React.FC<Props> = ({
  requestId
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(
      state,
      `${Loaders.UpdatePermissionRequestsStatus}-${requestId}`
    )
  )
  const handleApprove = useCallback(() => {
    dispatch(
      updatePermissionRequestsStatus([
        {
          id: requestId,
          status: RoleRequestStatus.Approved
        }
      ])
    )
  }, [requestId, dispatch])

  const handleReject = useCallback(() => {
    dispatch(
      updatePermissionRequestsStatus([
        {
          id: requestId,
          status: RoleRequestStatus.Rejected
        }
      ])
    )
  }, [requestId, dispatch])

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader className={styles.loader} />}
      <Button
        ghost
        disabled={isLoading}
        onClick={handleApprove}
        type="primary"
        className={classNames(styles.button, styles.approve, {
          [styles.loading]: isLoading
        })}
      >
        {formatMessage({
          id: 'component.permission-requests.actions.label.approve'
        })}
      </Button>
      <Button
        ghost
        danger
        disabled={isLoading}
        className={classNames(styles.button, {
          [styles.loading]: isLoading
        })}
        onClick={handleReject}
      >
        {formatMessage({
          id: 'component.permission-requests.actions.label.reject'
        })}
      </Button>
    </div>
  )
}
