import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { handleError } from 'redux/actions/errors'
import { FETCH_LOGIN_HISTORY } from 'redux/actions/login-history/constants'
import { consumeLoginHistory } from 'redux/actions/login-history'
import { mapLoginHistoryItems } from 'redux/utils/login-history'

function* handleFetchLoginHistory() {
  yield put(startLoading(Loaders.FetchLoginHistory))

  try {
    const { data } = yield call(api.loginHistory)

    if (data) {
      yield put(consumeLoginHistory(mapLoginHistoryItems(data)))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchLoginHistory))
  }
}

export function* loginHistory() {
  yield all([
    takeLatest(FETCH_LOGIN_HISTORY, handleFetchLoginHistory)
  ])
}
