import { RootState } from 'redux/types/store'
import { createSelector } from 'reselect'
import { PermissionOperation } from 'redux/reducers/permission-operations/types'

export const getOperations = (state: RootState) => state.permissionOperations.items

export const getDefaultSelectedOperationId = createSelector(
  getOperations,
  operations => {
    const operation = operations
      .find(({ name }) => name === PermissionOperation.Get)

    return operation
      ? operation.id
      : null
  }
)