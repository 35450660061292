import { ReducersType } from 'redux/reducers/types'

export const FETCH_PERMISSIONS = `${ReducersType.Permissions}/fetchPermissions`
export const CONSUME_PERMISSIONS = `${ReducersType.Permissions}/consumePermissions`
export const SET_PERMISSION_NAME = `${ReducersType.Permissions}/setPermissionName`
export const SET_PERMISSION_LABEL = `${ReducersType.Permissions}/setPermissionLabel`
export const SET_SELECTED_ROLE_ID = `${ReducersType.Permissions}/setSelectedRoleId`
export const REMOVE_SELECTED_ROLE_ID = `${ReducersType.Permissions}/removeSelectedRoleId`
export const CLEAR_SELECTED_ROLE_IDS = `${ReducersType.Permissions}/clearSelectedRoleIds`
export const SET_SELECTED_OPERATION_ID = `${ReducersType.Permissions}/setSelectedOperationId`
export const REMOVE_SELECTED_OPERATION_ID = `${ReducersType.Permissions}/removeSelectedOperationId`
export const CLEAR_SELECTED_OPERATION_IDS = `${ReducersType.Permissions}/clearSelectedOperationIds`
export const SUBMIT_PERMISSION = `${ReducersType.Permissions}/submitPermission`