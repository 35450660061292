import { RootState } from 'redux/types/store'
import { SystemType } from 'redux/reducers/systems/types'
import { createSelector } from 'reselect'

export const getSystems = (state: RootState): SystemType[] | null =>
  state.systems.systems

export const getSelectedSystemId = (state: RootState): number | null =>
  state.systems.selectedSystemId

export const getSelectedSystem = createSelector(
  getSelectedSystemId,
  getSystems,
  (selectedSystemId, systems): SystemType | null => {
    if (!systems || !selectedSystemId) {
      return null
    }

    return systems.find(({ id }) => id === selectedSystemId) || null
  }
)
