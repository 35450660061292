import React from 'react'
import classNames from 'classnames'
import { useSelector} from 'react-redux'
import { RoleNames } from 'redux/reducers/roles/types'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Divider } from 'antd'
import { AccountAvatar } from 'components/account/AccountAvatar'
import { AccountMeta } from 'components/account/AccountMeta'
import { getIsLoading } from 'redux/selectors/loaders'
import { checkPermissionsBySystem, getUserEmail } from 'redux/selectors/account'
import styles from 'components/account/AccountBaseInfo/styles.module.css'

interface Props {
  avatarSize?: number
  avatarClassName?: string
}

const DEFAULT_AVATAR_SIZE = 64

export const AccountBaseInfo: React.FC<Props> = React.memo(({
  avatarSize = DEFAULT_AVATAR_SIZE,
  avatarClassName,
}) => {
  const email = useSelector(getUserEmail)
  const hasPermissions = useSelector(checkPermissionsBySystem)
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.FetchAccountInfo))

  return (
    <div className={styles.wrapper}>
      <AccountAvatar
        size={avatarSize}
        isLoading={isLoading}
        className={classNames(styles.avatar, avatarClassName)}
      />
      <Divider className={styles.divider}/>
      {hasPermissions && (
        <AccountMeta
          email={email}
          role={RoleNames.Admin}
          isLoading={isLoading}
        />
      )}
    </div>
  )
})