import { createAction } from '@reduxjs/toolkit'
import {
  CHECK_SESSION,
  LOG_OUT,
  LOGGED_IN,
  LOGGED_OUT,
} from 'redux/actions/auth/constants'

export const checkSession = createAction(CHECK_SESSION)

export const loggedIn = createAction(LOGGED_IN)

export const loggedOut = createAction(LOGGED_OUT)

export const logOut = createAction(LOG_OUT)