import React from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'antd'
import { Routes } from 'routes/constants'
import { Navigate } from 'react-router-dom'
import { PageTitle } from 'components/common/PageTitle'
import { checkPermissionsBySystem } from 'redux/selectors/account'
import styles from 'routes/Unauthorized/styles.module.css'

//TODO: react-intl
export const Unauthorized: React.FC = () => {
  const hasPermissions = useSelector(checkPermissionsBySystem)

  if (hasPermissions) {
    return <Navigate to={Routes.Index} />
  }

  return (
    <div>
      <PageTitle text="No Access" />
      <Alert
        className={styles.alert}
        message="No Permissions"
        description="You have no permissions to access the admin panel. Please contact your administrator to request access."
        type="error"
        showIcon
      />
    </div>
  )
}
