import { configureStore } from '@reduxjs/toolkit'
import {
  account,
  auth,
  errors,
  loaders,
  modals,
  roles,
  systems,
  users,
  permissions,
  permissionRequests,
  permissionOperations,
  loginHistory
} from 'redux/reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'redux/sagas'

const sagaMiddleware = createSagaMiddleware()

export const reducer = {
  account: account.reducer,
  auth: auth.reducer,
  errors: errors.reducer,
  loaders: loaders.reducer,
  modals: modals.reducer,
  roles: roles.reducer,
  systems: systems.reducer,
  users: users.reducer,
  permissions: permissions.reducer,
  permissionRequests: permissionRequests.reducer,
  permissionOperations: permissionOperations.reducer,
  loginHistory: loginHistory.reducer
}

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export default store
