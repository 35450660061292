import { createSelector } from 'reselect'
import { RootState } from 'redux/types/store'
import {
  UserType,
  UserByRoleType,
  UserByRoleTypeWithLoginItems
} from 'redux/reducers/users/types'
import { getLoginHistoryItems } from './login-history'
import { endOfDay, getTime, subDays } from 'date-fns'
import { LoginHistoryItem } from 'redux/reducers/login-history/types'

export const getUsers = (state: RootState): UserType[] | null =>
  state.users.users

export const getUsersByRole = (
  state: RootState
): UserByRoleType[] | null => state.users.usersByRole

export const getEditedUserByRoleId = (
  state: RootState
): number | null => state.users.editedUserByRoleId

export const getDeletedUserId = (state: RootState): number | null =>
  state.users.deletedUserId

export const getSearchText = (state: RootState): string =>
  state.users.searchText

export const getUsersWithoutRoles = createSelector(
  getUsers,
  getUsersByRole,
  (users, usersByRole) =>
    users &&
    users.filter(({ email }) =>
      usersByRole
        ? !usersByRole.some(
            (userByRole) => userByRole.email === email
          )
        : true
    )
)

export const getEditedUserByRole = createSelector(
  getUsersByRole,
  getEditedUserByRoleId,
  (usersByRole, editedUserByRoleId) =>
    usersByRole
      ? usersByRole.find(({ id }) => id === editedUserByRoleId)
      : null
)

export const getDeletedUserById = createSelector(
  getUsersByRole,
  getDeletedUserId,
  (usersByRole, deletedUserId) =>
    usersByRole
      ? usersByRole.find(({ id }) => id === deletedUserId)
      : null
)

export const getUsersWithLoginStats = createSelector(
  getLoginHistoryItems,
  getUsersByRole,
  (loginItems, users) => {
    if (!users) {
      return []
    }

    const usersWithLoginItems = mapUsersToUsersWithLoginItems(
      loginItems,
      users
    )

    return usersWithLoginItems
  }
)

export const getFilteredUsers = createSelector(
  getUsersWithLoginStats,
  getSearchText,
  (users, searchText) => {
    if (!users) {
      return []
    }

    if (!searchText) {
      return users
    }

    const filteredUsers = users.filter((user) =>
      user.email.toLowerCase().includes(searchText.toLowerCase())
    )

    return filteredUsers
  }
)

const mapUsersToUsersWithLoginItems = (
  loginItems: LoginHistoryItem[],
  users: UserByRoleType[]
): UserByRoleTypeWithLoginItems[] => {
  const result = users.map((user) => {
    const loginLast30daysCount = getLoginHistoryByRange(
      loginItems,
      30,
      user.id
    ).length

    const loginLast90daysCount = getLoginHistoryByRange(
      loginItems,
      90,
      user.id
    ).length

    const totalLoginsCount = getLoginHistoryByRange(
      loginItems,
      null,
      user.id
    ).length

    return {
      ...user,
      loginLast30daysCount,
      loginLast90daysCount,
      totalLoginsCount
    }
  })

  return result
}

const getLoginHistoryByRange = (
  loginItems: LoginHistoryItem[],
  daysBack: number | null,
  id: number
) => {
  const filteredLoginItems = loginItems.filter(
    ({ userId }) => userId === id
  )

  if (!daysBack) return filteredLoginItems

  const startDate = getTime(subDays(endOfDay(new Date()), daysBack))

  return filteredLoginItems.filter(
    ({ createdAt }) => createdAt >= startDate
  )
}
