const messages = {
  'component.modals.add-permission.label.add-permission':
    'Add permission',
  'component.modals.add-permission.placeholder.enter-permission-name':
    'Enter permission name',
  'component.modals.add-permission.placeholder.enter-permission-label':
    'Enter permission label',
  'component.modals.add-user-by-role.label.add-user': 'Add user',
  'component.modals.delete-user-by-role.label.delete': 'Delete',
  'component.modals.delete-user-by-role.modal-confirmation-text':
    'Are you sure you want to delete user {email} ?',
  'component.modals.edit-user-by-role.label.edit': 'Confirm Edit',
  'component.modals.modals-container.title.addUserByRole': 'Add User',
  'component.modals.modals-container.title.editUserByRole':
    'Edit User',
  'component.modals.modals-container.title.deleteUserByRole':
    'Delete User',
  'component.modals.modals-container.title.addPermission':
    'Add Permission'
}

export default messages
