import config from 'config'
import { stringify } from 'qs'

interface NavigateExternalOptions {
  query?: Record<string, string>
}

export const navigateExternal = (
  url: string,
  { query }: NavigateExternalOptions = {}
) => {
  const queryString = stringify(query)
  const formattedUrl = `${url}?${queryString}`

  window.location.replace(formattedUrl)
}

export const navigateToLoginPage = (
  options?: NavigateExternalOptions
) => navigateExternal(config.okta.loginUri, options)

export const navigateToLogoutPage = (
  options?: NavigateExternalOptions
) => navigateExternal(config.okta.postLogoutRedirectUri, options)
