import React from 'react'
import { LoginHistoryDayRangeChart } from 'routes/Dashboard/LoginHistoryDayRangeChart'
import styles from './styles.module.css'

export const LoginHistoryDayRangeStats: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <LoginHistoryDayRangeChart daysBack={30}/>
      <LoginHistoryDayRangeChart daysBack={90}/>
    </div>
  )
}