import { all, put, call, takeLatest } from 'redux-saga/effects'
import api from 'api'
import {
  FETCH_SYSTEMS_DATA,
  SET_SELECTED_SYSTEM
} from 'redux/actions/systems/constants'
import { Loaders } from 'redux/reducers/loaders/types'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { consumeSystemsData } from 'redux/actions/systems'
import { fetchUsersByRole } from 'redux/actions/users'
import { handleError } from 'redux/actions/errors'

function* handleFetchSystemsData() {
  yield put(startLoading(Loaders.FetchSystemsData))

  try {
    const { data } = yield call(api.systems)

    if (data) {
      yield put(consumeSystemsData({ systems: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchSystemsData))
  }
}

function* handleSetSelectedSystem() {
  yield put(fetchUsersByRole())
}

export function* systems() {
  yield all([
    takeLatest(FETCH_SYSTEMS_DATA, handleFetchSystemsData),
    takeLatest(SET_SELECTED_SYSTEM, handleSetSelectedSystem)
  ])
}
