import account from './account'
import layout from './layout'
import modals from './modals'
import permissionOperations from './permission-operations'
import users from './users'
import permissionRequests from './permission-requests'
import permissions from './permissions'
import roles from './roles'

const messages = {
  ...account,
  ...layout,
  ...modals,
  ...permissionOperations,
  ...users,
  ...permissionRequests,
  ...permissions,
  ...roles
}

export default messages
