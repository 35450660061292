import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { Button } from 'antd'
import { RoleSelect } from 'components/roles/RoleSelect'
import { OperationsSelect } from 'components/permission-operations/OperationsSelect'
import {
  getPermissionName,
  getPermissionLabel,
  getSelectedRoleIds,
  getSelectedOperationIds
} from 'redux/selectors/permissions'
import { getIsLoading } from 'redux/selectors/loaders'
import { getAdminRoleId } from 'redux/selectors/roles'
import { getDefaultSelectedOperationId } from 'redux/selectors/permission-operations'
import {
  setPermissionName,
  setPermissionLabel,
  setSelectedRoleId,
  setSelectedOperationId,
  removeSelectedRoleId,
  removeSelectedOperationId,
  clearSelectedRoleIds,
  submitPermission,
  clearSelectedOperationIds
} from 'redux/actions/permissions'
import styles from './styles.module.css'
import { useIntl } from 'react-intl'

export const AddPermissionModal: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const defaultSelectedRoleId = useSelector(getAdminRoleId)
  const defaultSelectedOperationId = useSelector(
    getDefaultSelectedOperationId
  )
  const permissionName = useSelector(getPermissionName)
  const permissionLabel = useSelector(getPermissionLabel)
  const selectedRoleIds = useSelector(getSelectedRoleIds)
  const selectedOperationIds = useSelector(getSelectedOperationIds)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.AddPermission)
  )
  const isSubmitDisabled =
    !permissionName ||
    !permissionLabel ||
    !selectedOperationIds.length
  const handleChangeName = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setPermissionName(value)),
    [dispatch]
  )

  const handleChangeLabel = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setPermissionLabel(value)),
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(clearSelectedRoleIds())
      dispatch(clearSelectedOperationIds())
    }
  }, [dispatch])

  useEffect(() => {
    if (!selectedRoleIds.length && defaultSelectedRoleId) {
      dispatch(setSelectedRoleId(defaultSelectedRoleId))
    }

    if (!selectedOperationIds.length && defaultSelectedOperationId) {
      dispatch(setSelectedOperationId(defaultSelectedOperationId))
    }
  }, [
    dispatch,
    selectedRoleIds,
    selectedOperationIds,
    defaultSelectedRoleId,
    defaultSelectedOperationId
  ])

  const handleSelectRole = useCallback(
    (id: number) => {
      dispatch(setSelectedRoleId(id))
    },
    [dispatch]
  )

  const handleDeselectRole = useCallback(
    (id: number) => {
      dispatch(removeSelectedRoleId(id))
    },
    [dispatch]
  )

  const handleSelectOperation = useCallback(
    (id: number) => {
      dispatch(setSelectedOperationId(id))
    },
    [dispatch]
  )

  const handleDeselectOperation = useCallback(
    (id: number) => {
      dispatch(removeSelectedOperationId(id))
    },
    [dispatch]
  )

  const handleSubmit = useCallback(() => {
    dispatch(submitPermission())
  }, [dispatch])

  return (
    <div className={styles.wrapper}>
      <RoleSelect
        mode="multiple"
        value={selectedRoleIds}
        onSelect={handleSelectRole}
        onDeselect={handleDeselectRole}
      />
      <Input
        className={styles.textInput}
        placeholder={formatMessage({
          id: 'component.modals.add-permission.placeholder.enter-permission-name'
        })}
        value={permissionName}
        onChange={handleChangeName}
      />
      <Input
        className={styles.textInput}
        placeholder={formatMessage({
          id: 'component.modals.add-permission.placeholder.enter-permission-label'
        })}
        value={permissionLabel}
        onChange={handleChangeLabel}
      />
      <OperationsSelect
        mode="multiple"
        value={selectedOperationIds}
        onSelect={handleSelectOperation}
        onDeselect={handleDeselectOperation}
      />
      <Button
        type="primary"
        loading={isLoading}
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
        className={styles.submit}
      >
        {formatMessage({
          id: 'component.modals.add-permission.label.add-permission'
        })}
      </Button>
    </div>
  )
}
