import { TableColumnType } from 'components/common/Table'
import { UsersByRoleTableColumnType } from 'components/users/UsersByRole/types'
import { SortDirection } from 'react-virtualized'

export const USERS_TABLE_COLUMNS: TableColumnType[] = [
  {
    id: UsersByRoleTableColumnType.Id,
    dataKey: UsersByRoleTableColumnType.Id,
    label: 'ID',
    width: 80,
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: UsersByRoleTableColumnType.Email,
    dataKey: UsersByRoleTableColumnType.Email,
    label: 'Email',
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: UsersByRoleTableColumnType.Roles,
    dataKey: UsersByRoleTableColumnType.Roles,
    label: 'Role'
  },
  {
    id: UsersByRoleTableColumnType.LastLogin,
    dataKey: UsersByRoleTableColumnType.LastLogin,
    label: 'Last Seen On',
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: UsersByRoleTableColumnType.TotalLogins,
    dataKey: UsersByRoleTableColumnType.TotalLogins,
    label: 'Total Logins',
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: UsersByRoleTableColumnType.LoginsLast30days,
    dataKey: UsersByRoleTableColumnType.LoginsLast30days,
    label: 'Logins last 30 days',
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: UsersByRoleTableColumnType.LoginsLast90days,
    dataKey: UsersByRoleTableColumnType.LoginsLast90days,
    label: 'Logins last 90 days',
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: UsersByRoleTableColumnType.Edit,
    dataKey: UsersByRoleTableColumnType.Edit,
    label: 'Edit',
    width: 50
  },
  {
    id: UsersByRoleTableColumnType.Delete,
    dataKey: UsersByRoleTableColumnType.Delete,
    label: 'Delete',
    width: 70
  }
]

export const DAYS_BACK_BY_DATA_KEY: Record<string, number> = {
  [UsersByRoleTableColumnType.LoginsLast30days]: 30,
  [UsersByRoleTableColumnType.LoginsLast90days]: 90
}
