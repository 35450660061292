import { createSlice } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { AuthInitialState } from 'redux/reducers/auth/constants'

export const auth = createSlice({
  name: ReducersType.Auth,
  initialState: AuthInitialState,
  reducers: {
    loggedIn: state => {
      state.isLoggedIn = true
    },
    loggedOut: state => {
      state.isLoggedIn = false
    }
  }
})
