import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { PermissionsStateType } from 'redux/reducers/permissions/types'
import {
  ConsumePermissionsPayload,
  SetPermissionNamePayload,
  SetPermissionLabelPayload,
  SetSelectedRoleIdPayload
} from 'redux/actions/permissions/types'
import { PermissionsInitialState } from 'redux/reducers/permissions/constants'

export const permissions = createSlice({
  name: ReducersType.Permissions,
  initialState: PermissionsInitialState,
  reducers: {
    consumePermissions: (state: PermissionsStateType, {
      payload: { items }
    }: PayloadAction<ConsumePermissionsPayload>) => ({
      ...state,
      items
    }),
    setPermissionName: (state: PermissionsStateType, {
      payload
    }: PayloadAction<SetPermissionNamePayload>) => ({
      ...state,
      permissionName: payload
    }),
    setPermissionLabel: (state: PermissionsStateType, {
      payload
    }: PayloadAction<SetPermissionLabelPayload>) => ({
      ...state,
      permissionLabel: payload
    }),
    setSelectedRoleId: (state: PermissionsStateType, {
      payload
    }: PayloadAction<SetSelectedRoleIdPayload>) => ({
      ...state,
      selectedRoleIds: [...state.selectedRoleIds, payload]
    }),
    removeSelectedRoleId: (state: PermissionsStateType, {
      payload
    }: PayloadAction<SetSelectedRoleIdPayload>) => ({
      ...state,
      selectedRoleIds: state.selectedRoleIds.filter(id => id !== payload)
    }),
    setSelectedOperationId: (state: PermissionsStateType, {
      payload
    }: PayloadAction<SetSelectedRoleIdPayload>) => ({
      ...state,
      selectedOperationIds: [...state.selectedOperationIds, payload]
    }),
    removeSelectedOperationId: (state: PermissionsStateType, {
      payload
    }: PayloadAction<SetSelectedRoleIdPayload>) => ({
      ...state,
      selectedOperationIds: state.selectedOperationIds.filter(id => id !== payload)
    }),
    clearSelectedRoleIds: (state: PermissionsStateType) => ({
      ...state,
      selectedRoleIds: PermissionsInitialState.selectedRoleIds
    }),
    clearSelectedOperationIds: (state: PermissionsStateType) => ({
      ...state,
      selectedOperationIds: PermissionsInitialState.selectedOperationIds
    })
  }
})
