import { all } from 'redux-saga/effects'
import { account } from 'redux/sagas/account'
import { auth } from 'redux/sagas/auth'
import { errors } from 'redux/sagas/errors'
import { roles } from 'redux/sagas/roles'
import { systems } from 'redux/sagas/systems'
import { users } from 'redux/sagas/users'
import { permissions } from 'redux/sagas/permissions'
import { permissionRequests } from 'redux/sagas/permission-requests'
import { permissionOperations } from 'redux/sagas/permission-operations'
import { loginHistory } from 'redux/sagas/login-history'

export default function* rootSaga () {
  yield all([
    account(),
    auth(),
    errors(),
    roles(),
    systems(),
    users(),
    permissions(),
    permissionRequests(),
    permissionOperations(),
    loginHistory()
  ])
}