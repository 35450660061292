import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Button } from 'antd'
import { UserSelect } from 'components/users/UserSelect'
import { RoleSelect } from 'components/roles/RoleSelect'
import { addUserByRole, fetchUsers } from 'redux/actions/users'
import { getUsersWithoutRoles } from 'redux/selectors/users'
import { getRoles } from 'redux/selectors/roles'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from 'components/modals/AddUserByRole/styles.module.css'
import { useIntl } from 'react-intl'

export const AddUserByRoleModal: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const users = useSelector(getUsersWithoutRoles)
  const roles = useSelector(getRoles)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.AddUserByRole)
  )
  const [selectedUserId, setSelectedUserId] = useState<number>()
  const [selectedRoleId, setSelectedRoleId] = useState<number>()
  const isSubmitDisabled =
    !selectedRoleId || !selectedUserId || isLoading

  const handleAddUser = useCallback(() => {
    if (selectedUserId && selectedRoleId) {
      dispatch(
        addUserByRole({
          id: selectedUserId,
          roleId: selectedRoleId
        })
      )
    }
  }, [selectedUserId, selectedRoleId, dispatch])

  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  useEffect(() => {
    if (!selectedUserId && users && users.length) {
      const [firstUser] = users
      const { id } = firstUser

      setSelectedUserId(id)
    }
  }, [users, selectedUserId, setSelectedUserId])

  useEffect(() => {
    if (!selectedRoleId && roles && roles.length) {
      const [firstRole] = roles
      const {
        role: { id }
      } = firstRole

      setSelectedRoleId(id)
    }
  }, [roles, selectedRoleId, setSelectedRoleId])

  return (
    <div className={styles.wrapper}>
      <UserSelect
        selectedUserId={selectedUserId}
        onSelect={setSelectedUserId}
      />
      <RoleSelect
        value={selectedRoleId}
        onSelect={setSelectedRoleId}
      />
      <Button
        type="primary"
        loading={isLoading}
        disabled={isSubmitDisabled}
        onClick={handleAddUser}
        className={styles.submit}
      >
        {formatMessage({
          id: 'component.modals.add-user-by-role.label.add-user'
        })}
      </Button>
    </div>
  )
}
