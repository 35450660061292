import React from 'react'
import classNames from 'classnames'
import { Card as BaseCard } from 'antd'
import { Loader } from 'components/common/Loader'
import styles from 'components/common/Card/styles.module.css'

export interface Props {
  children: React.ReactNode
  title?: React.ReactNode
  extra?: React.ReactNode
  isLoading?: boolean
  className?: string
  contentClassName?: string
}

export const Card: React.FC<Props> = ({
  title,
  extra,
  isLoading,
  className,
  contentClassName,
  children
}) => {
  return (
    <BaseCard
      title={title}
      extra={extra}
      className={classNames(styles.wrapper, className)}
    >
      <div className={classNames(
        styles.content,
        contentClassName,
        { [styles.loading]: isLoading }
      )}>
        {isLoading ? <Loader size={24} /> : children}
      </div>
    </BaseCard>
  )
}