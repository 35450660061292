const messages = {
  'component.permissions.add-permission-button.label.add-permission':
    'Add Permission',
  'component.permissions.permission-list.table.title.permissionId':
    'ID',
  'component.permissions.permission-list.table.title.permissionName':
    'Name',
  'component.permissions.permission-list.table.title.operations':
    'Operations',
  'component.permissions.permission-list.table.title.roles': 'Roles',
  'component.permissions.permission-list.table.title.label': 'Label'
}

export default messages
