import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { Select, Typography } from 'antd'
import { RoleBadge } from 'components/common/RoleBadge'
import { getIsLoading } from 'redux/selectors/loaders'
import { getRoles } from 'redux/selectors/roles'
import { fetchRoles, clearRoles } from 'redux/actions/roles'
import styles from 'components/roles/RoleSelect/styles.module.css'
import { RoleType } from 'redux/reducers/roles/types'
import { useIntl } from 'react-intl'

const { Text } = Typography
const { Option } = Select

interface Props {
  value?: number | number[]
  mode?: 'multiple' | 'tags'
  onSelect: (id: number) => void
  onDeselect?: (id: number) => void
}

export const RoleSelect: React.FC<Props> = React.memo(
  ({ value, mode, onSelect, onDeselect }) => {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const roles = useSelector(getRoles)
    const isLoading = useSelector((state: RootState) =>
      getIsLoading(state, Loaders.FetchRoles)
    )

    const renderOption = useCallback(
      ({ system, role: { id, name } }: RoleType) => (
        <Option
          key={`${id}-${name}-${system}`}
          value={id}
        >
          <RoleBadge role={name} />
        </Option>
      ),
      []
    )

    useEffect(() => {
      dispatch(fetchRoles())

      return () => {
        dispatch(clearRoles())
      }
    }, [dispatch])

    return (
      <div className={styles.wrapper}>
        <Text className={styles.label}>
          {formatMessage({ id: 'component.roles.role-select.label' })}
        </Text>
        <Select
          mode={mode}
          value={value}
          loading={isLoading}
          disabled={isLoading}
          className={styles.select}
          onSelect={onSelect}
          onDeselect={onDeselect}
        >
          {roles && roles.map(renderOption)}
        </Select>
      </div>
    )
  }
)
