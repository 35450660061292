import React from 'react'
import { RoleNames } from 'redux/reducers/roles/types'
import { RoleBadge } from 'components/common/RoleBadge'
import { Typography } from 'antd'
import { Loader } from 'components/common/Loader'
import { SystemSelect } from 'components/systems/SystemSelect'
import styles from 'components/account/AccountMeta/styles.module.css'

const { Text } = Typography

export interface Props {
  email: string | null
  role: RoleNames
  isLoading?: boolean
}

export const AccountMeta: React.FC<Props> = React.memo(({
  email,
  role,
  isLoading
}) => {
  if (isLoading) {
    return <Loader/>
  }

  return (
    <>
      <Text className={styles.email}>{email}</Text>
      <RoleBadge role={role}/>
      <SystemSelect/>
    </>
  )
})