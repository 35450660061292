import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Select, Typography } from 'antd'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { getUsersWithoutRoles } from 'redux/selectors/users'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from 'components/users/UserSelect/styles.module.css'
import { UserType } from 'redux/reducers/users/types'
import { useIntl } from 'react-intl'

const { Text } = Typography
const { Option } = Select

interface Props {
  selectedUserId?: number
  onSelect: (id: number) => void
}

export const UserSelect: React.FC<Props> = ({
  selectedUserId,
  onSelect
}) => {
  const { formatMessage } = useIntl()
  const users = useSelector(getUsersWithoutRoles)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchUsers)
  )

  const renderOption = useCallback(
    ({ id, email }: UserType) => (
      <Option
        key={id}
        value={id}
      >
        {email}
      </Option>
    ),
    []
  )

  return (
    <div className={styles.wrapper}>
      <Text className={styles.label}>
        {formatMessage({ id: 'component.users.user-select.label' })}
      </Text>
      <Select
        value={selectedUserId}
        loading={isLoading}
        disabled={isLoading}
        placeholder={formatMessage({
          id: 'component.users.user-select.placeholder'
        })}
        className={styles.select}
        onSelect={onSelect}
      >
        {users && users.map(renderOption)}
      </Select>
    </div>
  )
}
