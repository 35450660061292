import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { ModalsInitialState } from 'redux/reducers/modals/constants'
import { ModalsStateType } from 'redux/reducers/modals/types'
import { ToggleModalPayload } from 'redux/actions/modals/types'

export const modals = createSlice({
  name: ReducersType.Modals,
  initialState: ModalsInitialState,
  reducers: {
    openModal: (state: ModalsStateType, { payload: { id } }: PayloadAction<ToggleModalPayload>) => ({
      ...state,
      openedModalId: id
    }),
    closeModal: (state: ModalsStateType, { payload: { id } }: PayloadAction<ToggleModalPayload>) => ({
      ...state,
      openedModalId: null
    })
  }
})
