const messages = {
  'component.users.add-user-button.label.add-user': 'Add user',
  'component.users.users-by-role.table.id': 'ID',
  'component.users.users-by-role.table.email': 'Email',
  'component.users.users-by-role.table.roles': 'Role',
  'component.users.users-by-role.table.lastLogin': 'Last Seen On',
  'component.users.users-by-role.table.totalLoginsCount':
    'Total Logins',
  'component.users.users-by-role.table.loginLast30daysCount':
    'Logins last 30 days',
  'component.users.users-by-role.table.loginLast90daysCount':
    'Logins last 90 days',
  'component.users.users-by-role.table.edit': 'Edit',
  'component.users.users-by-role.table.delete': 'Delete',
  'component.users.user-select.label': 'Select User',
  'component.users.user-select.placeholder': 'Select user',
  'component.users.users-by-role.search-bar.placeholder':
    'Search users by their emails'
}

export default messages
