import React from 'react'
import { Typography } from 'antd'
import styles from 'components/common/PageTitle/styles.module.css'

const { Title } = Typography

export interface Props {
  text: string
  level?: 1 | 2 | 3 | 4 | 5
}

const DEFAULT_TITLE_LEVEL = 3

export const PageTitle: React.FC<Props> = ({
  text,
  level = DEFAULT_TITLE_LEVEL
}) => {
  return (
    <Title
      level={level}
      className={styles.wrapper}
    >
      {text}
    </Title>
  )
}