const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// @monthNumber - expected format is 01-12
export const getMonthName = (monthNumber: string | number) => {
  const parsedMonth = Number(monthNumber)

  if (isNaN(parsedMonth) || parsedMonth < 1 || parsedMonth > 12) {
    return null
  }

  return MONTH_NAMES[parsedMonth-1]
}

// @day - expected format is 01-31
export const getDayOrdinalNumber = (day: string | number) => {
  const parsedDay = Number(day)

  if (isNaN(parsedDay) || parsedDay < 1 || parsedDay > 31) {
    return null
  }

  switch(day) {
    case 1: 
      return '1st'
    case 2: 
      return '2nd'
    case 3: 
      return '3rd'
    case 31: 
      return '31st'
    default:
      return `${day}th`
  }
}

// returns the date in the following format
// October 15th 2021, 10:06:26
export const formatDate = (date: string) => {
  const isRealDate = new Date(date).toString() !== 'Invalid Date';

  if (date && isRealDate) {
    const splittedDate = date.split('T')
    const time = splittedDate[1].split('.')[0]
    const yearPart = splittedDate[0].split('-')
    const year = yearPart[0]
    const month = yearPart[1]
    const day = yearPart[2]

    return `${getMonthName(month)} ${getDayOrdinalNumber(day)} ${year}, ${time}`
  }

  return null
}