import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { LoginHistoryInitialState } from 'redux/reducers/login-history/constants'
import { ConsumeLoginHistoryPayload } from 'redux/actions/login-history/types'

export const loginHistory = createSlice({
  name: ReducersType.LoginHistory,
  initialState: LoginHistoryInitialState,
  reducers: {
    consumeLoginHistory: (state, {
      payload: {
        items
      }
    }: PayloadAction<ConsumeLoginHistoryPayload>) => ({
      ...state,
      items
    })
  }
})
