import React from 'react'
import { useSelector } from 'react-redux'
import { Routes } from 'routes/constants'
import { NavLink } from 'components/layout/LeftSide/NavLink'
import { AccountBaseInfo } from 'components/account/AccountBaseInfo'
import { checkPermissionsBySystem } from 'redux/selectors/account'
import styles from 'components/layout/LeftSide/styles.module.css'
import { useIntl } from 'react-intl'

interface StateProps {}

export type Props = StateProps

export const LeftSide: React.FC<Props> = () => {
  const { formatMessage } = useIntl()
  const hasPermissions = useSelector(checkPermissionsBySystem)

  return (
    <div className={styles.wrapper}>
      <AccountBaseInfo />
      {hasPermissions && (
        <>
          <NavLink
            to={Routes.Index}
            label={formatMessage({
              id: 'component.left-side.label.dashboard'
            })}
          />
          <NavLink
            to={Routes.Profile}
            label={formatMessage({
              id: 'component.left-side.label.profile'
            })}
          />
          <NavLink
            to={Routes.Permissions}
            label={formatMessage({
              id: 'component.left-side.label.permissions'
            })}
          />
          <NavLink
            to={Routes.PermissionRequests}
            label={formatMessage({
              id: 'component.left-side.label.permission-requests'
            })}
          />
        </>
      )}
    </div>
  )
}
