import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { PERMISSIONS_TABLE_COLUMNS } from 'components/permissions/PermissionsList/constants'
import { Card } from 'components/common/Card'
import { LabelFormatterProps, Table } from 'components/common/Table'
import { AddPermissionButton } from 'components/permissions/AddPermissionButton'
import { PermissionRolesList } from 'components/permissions/PermissionsList/RolesList'
import { OperationsList } from 'components/permissions/PermissionsList/OperationsList'
import { getIsLoading } from 'redux/selectors/loaders'
import { getPermissionsBySystem } from 'redux/selectors/permissions'
import { fetchPermissions } from 'redux/actions/permissions'
import styles from './styles.module.css'
import { TableCellProps } from 'react-virtualized'
import { useIntl } from 'react-intl'

export const PermissionsList: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const items = useSelector(getPermissionsBySystem)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchPermissions)
  )

  const cellRenderer = useCallback(
    ({ dataKey, cellData }: TableCellProps) => {
      if (dataKey === 'roles') {
        return <PermissionRolesList items={cellData} />
      }

      if (dataKey === 'operations') {
        return <OperationsList items={cellData} />
      }

      return cellData
    },
    []
  )

  const labelFormatter = useCallback(
    ({ dataKey }: LabelFormatterProps) =>
      formatMessage({
        id: `component.permissions.permission-list.table.title.${dataKey}`
      }),
    [formatMessage]
  )

  useEffect(() => {
    dispatch(fetchPermissions())
  }, [dispatch])

  return (
    <Card
      title="Permissions"
      isLoading={isLoading}
      contentClassName={styles.wrapper}
      extra={<AddPermissionButton />}
    >
      {items && (
        <Table
          columns={PERMISSIONS_TABLE_COLUMNS}
          rows={items}
          cellRenderer={cellRenderer}
          labelFormatter={labelFormatter}
        />
      )}
    </Card>
  )
}
