import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { AccountInitialState } from 'redux/reducers/account/constants'
import { AccountPayload } from 'redux/actions/account/types'

export const account = createSlice({
  name: ReducersType.Account,
  initialState: AccountInitialState,
  reducers: {
    consumeAccountInfo: (state, {
      payload: {
        id,
        email,
        roles,
        permissions
      }
    }: PayloadAction<AccountPayload>) => ({
      ...state,
      id,
      email,
      roles,
      permissions
    })
  }
})
