import {
  FETCH_REQUESTS,
  CONSUME_REQUESTS,
  UPDATE_REQUESTS_STATUS,
  SET_REQUESTS_STATUS
} from 'redux/actions/permission-requests/constants'
import { createAction } from '@reduxjs/toolkit'
import {
  ConsumePermissionRequestsPayload,
  PrepareUpdatePermissionRequestsStatusAction
} from 'redux/actions/permission-requests/types'

export const fetchPermissionRequests = createAction(FETCH_REQUESTS)

export const consumePermissionRequests = createAction<ConsumePermissionRequestsPayload>(CONSUME_REQUESTS)

export const updatePermissionRequestsStatus = createAction<PrepareUpdatePermissionRequestsStatusAction>(UPDATE_REQUESTS_STATUS, (
  items) => ({ payload: { items } }))

export const setRequestsStatus = createAction<PrepareUpdatePermissionRequestsStatusAction>(SET_REQUESTS_STATUS, (
  items) => ({ payload: { items } }))