import { TableColumnType } from 'components/common/Table'

export const PERMISSIONS_TABLE_COLUMNS: TableColumnType[] = [
  {
    id: 'id',
    dataKey: 'permissionId',
    label: 'ID',
    width: 50
  },
  {
    id: 'name',
    dataKey: 'permissionName',
    label: 'Name'
  },
  {
    id: 'operation',
    dataKey: 'operations',
    width: 300,
    label: 'Operations'
  },
  {
    id: 'roles',
    dataKey: 'roles',
    width: 180,
    label: 'Roles'
  },
  {
    id: 'label',
    dataKey: 'label',
    label: 'Label'
  }
]