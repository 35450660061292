import React from 'react'
import { Typography } from 'antd'
import styles from 'components/layout/Header/Logo/styles.module.css'
import { useIntl } from 'react-intl'

const { Text } = Typography

export interface Props {}

export const Logo: React.FC<Props> = () => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.wrapper}>
      <img
        src="nucleus-logo-white.png"
        className={styles.logo}
        alt="Nucleus logo"
      />
      <Text className={styles.logoText}>
        {formatMessage({ id: 'component.header.label.permissions' })}
      </Text>
    </div>
  )
}
