import {
  FETCH_PERMISSIONS,
  CONSUME_PERMISSIONS,
  SET_PERMISSION_NAME,
  SET_PERMISSION_LABEL,
  SET_SELECTED_OPERATION_ID,
  SET_SELECTED_ROLE_ID,
  REMOVE_SELECTED_OPERATION_ID,
  REMOVE_SELECTED_ROLE_ID,
  CLEAR_SELECTED_ROLE_IDS,
  CLEAR_SELECTED_OPERATION_IDS,
  SUBMIT_PERMISSION
} from 'redux/actions/permissions/constants'
import { createAction } from '@reduxjs/toolkit'
import {
  ConsumePermissionsPayload,
  SetPermissionNamePayload,
  SetPermissionLabelPayload,
  SetSelectedRoleIdPayload,
  SetSelectedOperationIdPayload,
} from 'redux/actions/permissions/types'

export const fetchPermissions = createAction(FETCH_PERMISSIONS)

export const consumePermissions = createAction<ConsumePermissionsPayload>(CONSUME_PERMISSIONS)

export const setPermissionName = createAction<SetPermissionNamePayload>(SET_PERMISSION_NAME)

export const setPermissionLabel = createAction<SetPermissionLabelPayload>(SET_PERMISSION_LABEL)

export const setSelectedRoleId = createAction<SetSelectedRoleIdPayload>(SET_SELECTED_ROLE_ID)

export const removeSelectedRoleId = createAction<SetSelectedRoleIdPayload>(REMOVE_SELECTED_ROLE_ID)

export const clearSelectedRoleIds = createAction(CLEAR_SELECTED_ROLE_IDS)

export const setSelectedOperationId = createAction<SetSelectedOperationIdPayload>(SET_SELECTED_OPERATION_ID)

export const removeSelectedOperationId = createAction<SetSelectedOperationIdPayload>(REMOVE_SELECTED_OPERATION_ID)

export const clearSelectedOperationIds = createAction(CLEAR_SELECTED_OPERATION_IDS)

export const submitPermission = createAction(SUBMIT_PERMISSION)


