import { LoginHistoryItem, RawLoginHistoryItem } from 'redux/reducers/login-history/types'
import { endOfDay, getTime } from 'date-fns'

export const mapLoginHistoryItems = (items: RawLoginHistoryItem[]): LoginHistoryItem[] =>
  items
    .map(({ userId, createdAt }) => ({
      userId,
      createdAt: new Date(createdAt).getTime()
    }))

export const mapUniqueUserLoginsByDate = (items: LoginHistoryItem[]) =>
  items
    .reduce((acc: Record<string, number[]>, {
      userId,
      createdAt
    }) => {
      const key = getTime(endOfDay(new Date(createdAt)))

      if (!acc[key]) {
        acc[key] = []
      }

      if (!acc[key].includes(userId)) {
        acc[key].push(userId)
      }

      return acc
    }, {})