import {
  all,
  call,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'
import api from 'api'
import { getSelectedSystemId } from 'redux/selectors/systems'
import { FETCH_ROLES } from 'redux/actions/roles/constants'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { consumeRoles } from 'redux/actions/roles'
import { handleError } from 'redux/actions/errors'

function* handleFetchRoles() {
  const selectedSystemId: number | null = yield select(
    getSelectedSystemId
  )

  yield put(startLoading(Loaders.FetchRoles))

  try {
    const { data } = yield call(api.roles, {
      queryParams: {
        system: selectedSystemId
      }
    })

    yield put(consumeRoles({ roles: data }))
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchRoles))
  }
}

export function* roles() {
  yield all([takeLatest(FETCH_ROLES, handleFetchRoles)])
}
