import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { DeleteFilled } from '@ant-design/icons'
import { deleteUserByRole } from 'redux/actions/users'
import styles from './styles.module.css'

interface Props {
  userId: number
}

export const DeleteUserButton: React.FC<Props> = ({
  userId
}) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
      dispatch(deleteUserByRole({ id: userId }))
    },
    [userId, dispatch]
  )

  return (
    <DeleteFilled
      className={styles.wrapper}
      onClick={handleClick}
    />
  )
}