import React from 'react'
import classNames from 'classnames'
import styles from 'components/common/GlobalLoader/styles.module.css'

export interface Props {
  className?: string
}

export const GlobalLoader: React.FC<Props> = ({ className }) => (
  <div className={classNames(styles.loader, className)} />
)