import React, { useCallback } from 'react'
import { PermissionRoleItem } from 'redux/reducers/permissions/types'
import { RoleBadge } from 'components/common/RoleBadge'
import styles from './styles.module.css'

interface Props {
  items: PermissionRoleItem[]
}

export const PermissionRolesList: React.FC<Props> = ({ items }) => {
  const renderRole = useCallback(
    ({ id, name }: PermissionRoleItem) => (
      <RoleBadge
        key={id}
        role={name}
        className={styles.badge}
      />
    ),
    []
  )

  return <div className={styles.wrapper}>{items.map(renderRole)}</div>
}
