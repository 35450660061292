import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'components/common/Card'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { getUserPermissions } from 'redux/selectors/account'
import { getIsLoading } from 'redux/selectors/loaders'

export const UserPermissions: React.FC = () => {
  const permissions = useSelector(getUserPermissions)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchAccountInfo)
  )

  const renderPermission = useCallback(
    (permission: string) => <div key={permission}>{permission}</div>,
    []
  )

  return (
    <Card
      title="Permissions"
      isLoading={isLoading}
    >
      <>{permissions && permissions.map(renderPermission)}</>
    </Card>
  )
}
