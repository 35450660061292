import React, { CSSProperties, useCallback } from 'react'
import classNames from 'classnames'
import {
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine
} from 'recharts'
import { Margin } from 'recharts/types/util/types'
import { CurveType } from 'recharts/types/shape/Curve'
import {
  LineChartDataItem,
  LineStrokeColorGetterPayload,
  YAxisTickFormatter,
  TooltipItemSorter,
  LegendContentType
} from 'components/common/LineChart/types'
import { ImplicitLabelType } from 'recharts/types/component/Label'
import { Colors } from 'constants/common/other/colors'
import {
  DEFAULT_CHART_WIDTH,
  DEFAULT_CHART_HEIGHT,
  DEFAULT_LINE_CHART_COLORS,
  DEFAULT_LINE_STROKE_WIDTH,
  DEFAULT_TOOLTIP_ITEM_STYLE,
  DEFAULT_LEGEND_WRAPPER_STYLE,
  DEFAULT_REFERENCE_LINE_STROKE_WIDTH,
  DEFAULT_SHOULD_RENDER_LINE_DOT,
  LINE_ACTIVE_DOT_PROPS
} from 'components/common/LineChart/constants'
import { formatNumberAbbreviateNonZeroReminder } from 'utils/common/other'
import { sortTooltipItemsByValueDesc } from 'components/common/LineChart/utils'
import styles from './styles.module.css'

export interface Props {
  data: LineChartDataItem[]
  dataKeys: string[]
  lineType?: CurveType
  width?: number | string
  height?: number | string
  margin?: Margin
  lineStrokeColor?: Colors | null
  lineStrokeWidth?: number
  lineLabel?: ImplicitLabelType
  lineDot?: React.ReactElement | boolean
  xAxisTicks?: string[]
  yAxisTickFormatter?: YAxisTickFormatter
  tooltipValueFormatter?: (value: number) => string
  tooltipItemSorter?: TooltipItemSorter
  legendContent?: LegendContentType
  legendWrapperStyle?: CSSProperties
  shouldRenderLineAxisReferenceLine?: boolean
  lineAxisReferenceLineY?: number
  getLineStrokeColor?: ({
    dataKey,
    index
  }: LineStrokeColorGetterPayload) => Colors
}

export const LineChart: React.FC<Props> = ({
  data,
  dataKeys,
  lineType,
  margin,
  lineStrokeColor,
  xAxisTicks,
  width = DEFAULT_CHART_WIDTH,
  height = DEFAULT_CHART_HEIGHT,
  lineStrokeWidth = DEFAULT_LINE_STROKE_WIDTH,
  legendWrapperStyle = DEFAULT_LEGEND_WRAPPER_STYLE,
  lineDot = DEFAULT_SHOULD_RENDER_LINE_DOT,
  lineLabel,
  yAxisTickFormatter = formatNumberAbbreviateNonZeroReminder,
  tooltipValueFormatter = formatNumberAbbreviateNonZeroReminder,
  tooltipItemSorter = sortTooltipItemsByValueDesc,
  legendContent,
  shouldRenderLineAxisReferenceLine,
  lineAxisReferenceLineY,
  getLineStrokeColor
}) => {
  const tooltipValFormatter = useCallback(
    (value: any) => {
      if (typeof value !== 'number') {
        return value
      }

      return tooltipValueFormatter(value)
    },
    [tooltipValueFormatter]
  )

  const lineStrokeColorGetter = useCallback(
    ({ dataKey, index }: LineStrokeColorGetterPayload): Colors => {
      if (getLineStrokeColor) {
        return getLineStrokeColor({ dataKey, index })
      }

      return lineStrokeColor || DEFAULT_LINE_CHART_COLORS[index]
    },
    [lineStrokeColor, getLineStrokeColor]
  )

  const renderLine = useCallback(
    (dataKey: string, index: number) => {
      const stroke = lineStrokeColorGetter({ dataKey, index })

      return (
        <Line
          key={dataKey}
          dataKey={dataKey}
          type={lineType}
          //@ts-ignore
          label={lineLabel}
          dot={lineDot}
          strokeWidth={lineStrokeWidth}
          activeDot={LINE_ACTIVE_DOT_PROPS}
          stroke={stroke}
        />
      )
    },
    [
      lineStrokeWidth,
      lineType,
      lineDot,
      lineLabel,
      lineStrokeColorGetter
    ]
  )

  return (
    <ResponsiveContainer
      width={width}
      height={height}
      className={classNames(styles.wrapper)}
    >
      <RechartsLineChart
        data={data}
        margin={margin}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          ticks={xAxisTicks}
        />
        <YAxis tickFormatter={yAxisTickFormatter} />
        <Tooltip
          labelClassName={styles.tooltipLabel}
          itemStyle={DEFAULT_TOOLTIP_ITEM_STYLE}
          itemSorter={tooltipItemSorter}
          formatter={tooltipValFormatter}
        />
        {shouldRenderLineAxisReferenceLine && (
          <ReferenceLine
            y={lineAxisReferenceLineY}
            stroke={Colors.corn}
            strokeDasharray="3 3"
            strokeWidth={DEFAULT_REFERENCE_LINE_STROKE_WIDTH}
          />
        )}
        <Legend
          wrapperStyle={legendWrapperStyle}
          content={legendContent}
        />
        {dataKeys.map(renderLine)}
      </RechartsLineChart>
    </ResponsiveContainer>
  )
}
