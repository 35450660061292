export enum Loaders {
  CheckSession = 'checkSession',
  FetchAccountInfo = 'fetchAccountInfo',
  FetchSystemsData = 'fetchSystemsData',
  FetchUsers = 'fetchUsers',
  FetchUsersByRole = 'fetchUsersByRole',
  FetchRoles = 'fetchRoles',
  AddUserByRole = 'addUserByRole',
  ConfirmEditUserByRole = 'confirmEditUserByRole',
  ConfirmDeleteUserByRole = 'confirmDeleteUserByRole',
  FetchPermissions = 'fetchPermissions',
  AddPermission = 'AddPermission',
  FetchPermissionRequests = 'fetchPermissionRequests',
  FetchPermissionOperations = 'fetchPermissionOperations',
  UpdatePermissionRequestsStatus = 'updatePermissionRequestsStatus',
  FetchLoginHistory = 'fetchLoginHistory'
}

export type LoadersStateType = Record<Loaders | string, boolean>