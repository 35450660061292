import React from 'react'
import classNames from 'classnames'
import { Tag } from 'antd'
import { RoleNames } from 'redux/reducers/roles/types'
import styles from 'components/common/RoleBadge/styles.module.css'
import { useIntl } from 'react-intl'

export interface Props {
  role: RoleNames
  className?: string
}

export const RoleBadge: React.FC<Props> = React.memo(
  ({ role, className }) => {
    const { formatMessage } = useIntl()

    return (
      <Tag
        className={classNames(
          styles.wrapper,
          styles[role.toLowerCase()],
          className
        )}
      >
        {formatMessage({
          id: `component.role-badge.label.role.${role}`
        })}
      </Tag>
    )
  }
)
