export enum ReducersType {
  Account = 'account',
  Auth = 'auth',
  Loaders = 'loaders',
  Modals = 'modals',
  Roles = 'roles',
  Systems = 'systems',
  Users = 'users',
  Errors = 'errors',
  Permissions = 'permission',
  PermissionRequests = 'permission-requests',
  PermissionOperations = 'permission-operations',
  LoginHistory = 'login-history'
}