import { FC, memo, useMemo } from 'react'

import { Componentify } from 'components/common/Componentify'
import { useSelector } from 'react-redux'
import { getSearchText } from 'redux/selectors/users'
import { HighlightedText } from 'components/common/HighlightedText'

export interface Props {
  email: string
}

export const onMatch = ({
  text,
  index
}: {
  text: string
  index: number
}) => (
  <HighlightedText
    text={text}
    key={`${text}-${index}`}
  />
)

export const UserEmail: FC<Props> = memo(({ email }) => {
  const searchText = useSelector(getSearchText)

  const patterns = useMemo(() => [searchText], [searchText])

  return (
    <Componentify
      text={email}
      patterns={patterns}
      onMatch={onMatch}
    />
  )
})
