import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { RootState } from 'redux/types/store'
import { RoleRequestStatus } from 'redux/reducers/permission-requests/types'
import { Loaders } from 'redux/reducers/loaders/types'
import { REQUESTS_TABLE_COLUMNS } from 'components/permission-requests/PermissionRequests/constants'
import { Card } from 'components/common/Card'
import { LabelFormatterProps, Table } from 'components/common/Table'
import { RoleBadge } from 'components/common/RoleBadge'
import { StatusBadge } from 'components/common/StatusBadge'
import { PermissionRequestActions } from 'components/permission-requests/PermissionRequests/Actions'
import { getIsLoading } from 'redux/selectors/loaders'
import { getSortedPermissionRequestItems } from 'redux/selectors/permission-requests'
import { fetchPermissionRequests } from 'redux/actions/permission-requests'
import styles from 'components/permission-requests/PermissionRequests/styles.module.css'
import { TableCellProps } from 'react-virtualized'
import { useIntl } from 'react-intl'

export const UserPermissionRequests: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const items = useSelector(getSortedPermissionRequestItems)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchPermissionRequests)
  )

  const cellRenderer = useCallback(
    ({
      dataKey,
      cellData,
      rowData: { id, status }
    }: TableCellProps) => {
      if (dataKey === 'roleName') {
        return <RoleBadge role={cellData} />
      }

      if (dataKey === 'status') {
        return <StatusBadge status={cellData} />
      }

      if (dataKey === 'createdAt' || dataKey === 'updatedAt') {
        return format(new Date(cellData), 'yyyy-MM-dd:hh:ss')
      }

      if (
        dataKey === 'actions' &&
        status === RoleRequestStatus.Pending
      ) {
        return <PermissionRequestActions requestId={id} />
      }

      return cellData
    },
    []
  )

  const labelFormatter = useCallback(
    ({ dataKey }: LabelFormatterProps) =>
      formatMessage({
        id: `component.permission-requests.permissions-requests.table.title.${dataKey}`
      }),
    [formatMessage]
  )

  useEffect(() => {
    dispatch(fetchPermissionRequests())
  }, [dispatch])

  return (
    <Card
      title="Requests"
      isLoading={isLoading}
      contentClassName={styles.wrapper}
    >
      {items && (
        <Table
          columns={REQUESTS_TABLE_COLUMNS}
          rows={items}
          cellRenderer={cellRenderer}
          labelFormatter={labelFormatter}
        />
      )}
    </Card>
  )
}
