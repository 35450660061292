import { ReducersType } from 'redux/reducers/types'

export const FETCH_USERS = `${ReducersType.Users}/fetchUsers`
export const FETCH_USERS_BY_ROLE = `${ReducersType.Users}/fetchUsersByRole`
export const CONSUME_USERS = `${ReducersType.Users}/consumeUsers`
export const CONSUME_USERS_BY_ROLE = `${ReducersType.Users}/consumeUsersByRole`
export const ADD_USER_BY_ROLE = `${ReducersType.Users}/addUserByRole`
export const EDIT_USER_BY_ROLE = `${ReducersType.Users}/editUserByRole`
export const DELETE_USER_BY_ROLE = `${ReducersType.Users}/deleteUserByRole`
export const CONFIRM_EDIT_USER_BY_ROLE = `${ReducersType.Users}/confirmEditUserByRole`
export const CONFIRM_DELETE_USER_BY_ROLE = `${ReducersType.Users}/confirmDeleteUserByRole`
export const STOP_EDIT_USER_BY_ROLE = `${ReducersType.Users}/stopEditUserByRole`
export const STOP_DELETE_USER_BY_ROLE = `${ReducersType.Users}/stopDeleteUserByRole`
export const SET_SEARCH = `${ReducersType.Users}/setSearchText`
