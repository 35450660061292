import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Button } from 'antd'
import { Componentify } from 'components/common/Componentify'
import { HighlightedText } from 'components/common/HighlightedText'
import { getDeletedUserById } from 'redux/selectors/users'
import { getIsLoading } from 'redux/selectors/loaders'
import {
  stopDeleteUserByRole,
  confirmDeleteUserByRole
} from 'redux/actions/users'
import styles from './styles.module.css'
import { useIntl } from 'react-intl'

export const DeleteUserByRoleModal: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const user = useSelector(getDeletedUserById)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.ConfirmDeleteUserByRole)
  )
  const { email = '' } = user || {}
  const text = formatMessage(
    {
      id: 'component.modals.delete-user-by-role.modal-confirmation-text'
    },
    { email }
  )

  const highlightPatterns = useMemo(() => [email], [email])

  const handleMatch = useCallback(
    ({ text, key }: { text: string; index: number; key: string }) => (
      <HighlightedText
        key={key}
        text={text}
      />
    ),
    []
  )

  const handleConfirmDelete = useCallback(() => {
    dispatch(confirmDeleteUserByRole())
  }, [dispatch])

  useEffect(
    () => () => {
      // Clears selected user id before unmount
      dispatch(stopDeleteUserByRole())
    },
    [dispatch]
  )

  return (
    <div className={styles.wrapper}>
      <Componentify
        text={text}
        patterns={highlightPatterns}
        onMatch={handleMatch}
      />
      <Button
        danger
        type="primary"
        loading={isLoading}
        className={styles.button}
        onClick={handleConfirmDelete}
      >
        {formatMessage({
          id: 'component.modals.delete-user-by-role.label.delete'
        })}
      </Button>
    </div>
  )
}
