import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { CHART_DATA_KEYS } from 'routes/Dashboard/LoginHistoryDayRangeChart/constants'
import { Card } from 'components/common/Card'
import { LineChart } from 'components/common/LineChart'
import { getLoginHistoryUniqueUserLoginsByDay } from 'redux/selectors/login-history'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'

interface Props {
  daysBack: number
}

export const LoginHistoryDayRangeChart: React.FC<Props> = ({
  daysBack
}) => {
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchLoginHistory))
  const uniqueLoginsByDay = useSelector((state: RootState) =>
    getLoginHistoryUniqueUserLoginsByDay(state, daysBack))
  // TODO: use react-intl
  const label = `Unique user logins by day - ${daysBack} days`

  return (
    <Card
      title={label}
      className={styles.wrapper}
      isLoading={isLoading}
    >
      <LineChart
        width="100%"
        height="100%"
        data={uniqueLoginsByDay}
        dataKeys={CHART_DATA_KEYS}
      />
    </Card>
  )
}