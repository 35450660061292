import React, { useCallback } from 'react'
import classNames from 'classnames'
import { TotalStatsItemValuePayload } from 'components/common/TotalStats/Item/types'
import { TotalStatsItem } from 'components/common/TotalStats/Item'
import { TotalStatsItemValue } from 'components/common/TotalStats/Item/Value'
import { Card } from 'components/common/Card'
import styles from './styles.module.css'

export interface Props<T extends string> {
  title: string
  sections: T[]
  isLoading?: boolean
  shouldRenderNoDataLabel?: boolean
  className?: string
  sectionClassName?: string
  sectionValueGetter: (id: T) => number | null
  sectionTitleGetter: (id: T) => string
  sectionValueFormatter?: ({
    id,
    value
  }: {
    id: T
    value: number
  }) => string
}

export const TotalStats = <T extends string>({
  title,
  sections,
  isLoading,
  className,
  sectionClassName,
  sectionValueGetter,
  sectionTitleGetter,
  sectionValueFormatter
}: Props<T>) => {
  const renderValue = useCallback(
    ({ id, value, formatter }: TotalStatsItemValuePayload) => (
      <TotalStatsItemValue
        key={id}
        value={value}
        formatter={formatter}
      />
    ),
    []
  )

  const renderSection = useCallback(
    (id: T, index: number) => {
      const value = sectionValueGetter(id)
      const sectionTitle = sectionTitleGetter(id)

      return (
        <TotalStatsItem
          id={id}
          key={id}
          title={sectionTitle}
          value={value}
          renderValue={renderValue}
          className={classNames(
            {
              [styles.firstSection]: index === 0
            },
            sectionClassName
          )}
          isLoading={isLoading}
          valueFormatter={sectionValueFormatter}
        />
      )
    },
    [
      isLoading,
      renderValue,
      sectionClassName,
      sectionValueGetter,
      sectionTitleGetter,
      sectionValueFormatter
    ]
  )

  return (
    <Card
      title={title}
      className={className}
      isLoading={isLoading}
    >
      <div className={styles.content}>
        {sections.map(renderSection)}
      </div>
    </Card>
  )
}
