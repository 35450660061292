import {
  all,
  call,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import {
  FETCH_PERMISSIONS,
  SUBMIT_PERMISSION
} from 'redux/actions/permissions/constants'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { handleError } from 'redux/actions/errors'
import {
  consumePermissions,
  fetchPermissions
} from 'redux/actions/permissions'
import {
  getPermissionName,
  getPermissionLabel,
  getSelectedRoleIds,
  getSelectedOperationIds
} from 'redux/selectors/permissions'
import { getSelectedSystemId } from 'redux/selectors/systems'
import { closeModal } from 'redux/actions/modals'
import { ModalsType } from 'redux/reducers/modals/types'

function* handleSubmitPermission() {
  const name: string = yield select(getPermissionName)
  const label: string = yield select(getPermissionLabel)
  const roleIds: number[] = yield select(getSelectedRoleIds)
  const operationIds: number[] = yield select(getSelectedOperationIds)
  const system: number = yield select(getSelectedSystemId)

  yield put(startLoading(Loaders.AddPermission))

  try {
    yield call(api.addPermission, {
      body: {
        name,
        label,
        system,
        roleIds,
        operationIds
      }
    })

    yield put(closeModal({ id: ModalsType.AddPermission }))
    yield put(fetchPermissions())
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.AddPermission))
  }
}

function* handleFetchPermissions() {
  yield put(startLoading(Loaders.FetchPermissions))

  try {
    const { data } = yield call(api.fetchPermissions)

    if (data) {
      yield put(consumePermissions({ items: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchPermissions))
  }
}

export function* permissions() {
  yield all([
    takeLatest(FETCH_PERMISSIONS, handleFetchPermissions),
    takeLatest(SUBMIT_PERMISSION, handleSubmitPermission)
  ])
}
