import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { PermissionOperationsInitialState } from 'redux/reducers/permission-operations/constants'
import { PermissionOperationsStateType } from 'redux/reducers/permission-operations/types'
import { ConsumePermissionOperationsPayload } from 'redux/actions/permission-operations/types'

export const permissionOperations = createSlice({
  name: ReducersType.PermissionOperations,
  initialState: PermissionOperationsInitialState,
  reducers: {
    consumeOperations: (state: PermissionOperationsStateType, {
      payload: { items }
    }: PayloadAction<ConsumePermissionOperationsPayload>) => ({
      ...state,
      items
    })
  }
})
