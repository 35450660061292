import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { EditFilled } from '@ant-design/icons'
import { editUserByRole } from 'redux/actions/users'
import styles from 'components/users/EditUserButton/styles.module.css'

interface Props {
  userId: number
}

export const EditUserButton: React.FC<Props> = ({
  userId
}) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
      dispatch(editUserByRole({ id: userId }))
    },
    [userId, dispatch]
  )

  return (
    <EditFilled
      className={styles.wrapper}
      onClick={handleClick}
    />
  )
}