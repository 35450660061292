import { RootState } from 'redux/types/store'
import { createSelector } from 'reselect'
import { getSelectedSystem } from 'redux/selectors/systems'

export const getUserEmail = (state: RootState): string | null =>
  state.account.email

export const getUserPermissions = (state: RootState): string[] | null =>
  state.account.permissions

export const checkPermissionsBySystem = createSelector(
  getSelectedSystem,
  getUserPermissions,
  (selectedSystem, permissions) => {
    if (!selectedSystem || !permissions) {
      return false
    }

    const requiredPermission = `admin.super-user.get`

    return permissions.includes(requiredPermission)
  }
)