import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { UpdatePermissionRequestsStatusPayload } from 'redux/actions/permission-requests/types'
import { UpdatedPermissionRequestItem } from 'redux/reducers/permission-requests/types'
import {
  FETCH_REQUESTS,
  UPDATE_REQUESTS_STATUS
} from 'redux/actions/permission-requests/constants'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { handleError } from 'redux/actions/errors'
import {
  consumePermissionRequests,
  setRequestsStatus
} from 'redux/actions/permission-requests'

function* handleFetchRequests() {
  yield put(startLoading(Loaders.FetchPermissionRequests))

  try {
    const { data } = yield call(api.fetchPermissionRequests)

    if (data) {
      yield put(consumePermissionRequests({ items: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchPermissionRequests))
  }
}

function* startLoaders(items: UpdatedPermissionRequestItem[]) {
  for (let i = 0; i < items.length; i++) {
    const { id } = items[i]

    yield put(
      startLoading(`${Loaders.UpdatePermissionRequestsStatus}-${id}`)
    )
  }
}

function* stopLoaders(items: UpdatedPermissionRequestItem[]) {
  for (let i = 0; i < items.length; i++) {
    const { id } = items[i]

    yield put(
      stopLoading(`${Loaders.UpdatePermissionRequestsStatus}-${id}`)
    )
  }
}

function* handleUpdateRequestsStatus({
  payload: { items }
}: PayloadAction<UpdatePermissionRequestsStatusPayload>) {
  yield call(startLoaders, items)

  try {
    yield call(api.updatePermissionRequests, {
      body: { items }
    })

    yield put(setRequestsStatus(items))
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield call(stopLoaders, items)
  }
}

export function* permissionRequests() {
  yield all([
    takeLatest(FETCH_REQUESTS, handleFetchRequests),
    takeEvery(UPDATE_REQUESTS_STATUS, handleUpdateRequestsStatus)
  ])
}
