import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from 'api'
import { FETCH_ACCOUNT_INFO } from 'redux/actions/account/constants'
import { Loaders } from 'redux/reducers/loaders/types'
import { getUserInfo } from 'api/okta-auth'
import { consumeAccountInfo } from 'redux/actions/account'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { handleError } from 'redux/actions/errors'

function* handleFetchAccountInfo() {
  yield put(startLoading(Loaders.FetchAccountInfo))

  try {
    const { email } = yield call(getUserInfo)
    const { data } = yield call(api.account, {
      queryParams: { email }
    })

    if (data) {
      const { id, email, roles, permissions } = data

      yield put(consumeAccountInfo({ id, email, roles, permissions }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchAccountInfo))
  }
}

export function* account() {
  yield all([takeLatest(FETCH_ACCOUNT_INFO, handleFetchAccountInfo)])
}
