export const config = {
  dev: {
    api: {
      url: 'https://nuc-permission-server-poxh6zzaha-ew.a.run.app'
    },
    okta: {
      issuer: 'https://newscorp.oktapreview.com/oauth2/default',
      clientId: '0oa1g9qnm7wIVEZ8M0h8',
      redirectUri: 'http://localhost:8000/user/login',
      postLogoutRedirectUri: 'http://localhost:8000/user/logout',
      loginUri: 'http://localhost:8000/user/login'
    }
  },
  staging: {
    api: {
      url: 'https://nuc-permission-server-poxh6zzaha-ew.a.run.app'
    },
    okta: {
      issuer: 'https://newscorp.oktapreview.com/oauth2/default',
      clientId: '0oa1g9p1novGYiuzq0h8',
      redirectUri: 'https://nucleus.staging-news.co.uk/user/login',
      postLogoutRedirectUri: 'https://nucleus.staging-news.co.uk/user/logout',
      loginUri: 'https://nucleus.staging-news.co.uk/user/login'
    }
  },
  prod: {
    api: {
      url: 'https://nuc-admin-server.news.co.uk'
    },
    okta: {
      issuer: 'https://newscorp.okta.com/oauth2/default',
      clientId: '0oaya3q9csRzA2HTE0x7',
      redirectUri: 'https://nucleus.news.co.uk/user/login',
      postLogoutRedirectUri: 'https://nucleus.news.co.uk/user/logout',
      loginUri: 'https://nucleus.news.co.uk/user/login'
    }
  }
}
