import React, { useCallback } from 'react'
import classNames from 'classnames'
import { TotalStatsItemValuePayload } from 'components/common/TotalStats/Item/types'
import { Typography } from 'antd'
import { Loader } from 'components/common/Loader'
import { TotalStatsItemValue } from 'components/common/TotalStats/Item/Value'
import { formatNumberCommaSeparator } from 'utils/common/other'
import styles from './styles.module.css'

const { Text } = Typography

export interface Props<T extends string> {
  id: T
  title: string
  value: number | null
  isLoading?: boolean
  className?: string
  valueFormatter?: ({
    id,
    value
  }: {
    id: T
    value: number
  }) => string
  renderValue?: (
    params: TotalStatsItemValuePayload
  ) => React.ReactElement
}

export const TotalStatsItem = <T extends string>({
  id,
  title,
  value,
  isLoading,
  className,
  valueFormatter,
  renderValue
}: Props<T>) => {
  const formatValue = useCallback(
    (value: number) =>
      valueFormatter
        ? valueFormatter({ id, value })
        : formatNumberCommaSeparator(value),
    [id, valueFormatter]
  )

  const renderItemValue = useCallback(
    () =>
      renderValue ? (
        renderValue({ id, value, formatter: formatValue })
      ) : (
        <TotalStatsItemValue
          formatter={formatValue}
          value={value}
        />
      ),
    [id, value, formatValue, renderValue]
  )

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Text className={styles.title}>
        {title}
      </Text>
      {isLoading ? <Loader /> : renderItemValue()}
    </div>
  )
}
