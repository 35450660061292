import { createSelector } from 'reselect'
import { getTime } from 'date-fns'
import { RootState } from 'redux/types/store'
import { PermissionRequestItem } from 'redux/reducers/permission-requests/types'
import orderBy from 'lodash/orderBy'

const getPermissionRequestItems = (state: RootState) =>
  state.permissionRequests.items

export const getSortedPermissionRequestItems = createSelector(
  getPermissionRequestItems,
  items => orderBy(items, [
    ({ createdAt }: PermissionRequestItem) => -getTime(new Date(createdAt))
  ])
)