import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { UsersInitialState } from 'redux/reducers/users/constants'
import {
  ConsumeUsersByRolePayload,
  ConsumeUsersPayload,
  EditUserByRolePayload,
  SetSearchTextPayload
} from 'redux/actions/users/types'

export const users = createSlice({
  name: ReducersType.Users,
  initialState: UsersInitialState,
  reducers: {
    consumeUsers: (
      state,
      { payload: { users } }: PayloadAction<ConsumeUsersPayload>
    ) => ({
      ...state,
      users
    }),
    consumeUsersByRole: (
      state,
      {
        payload: { usersByRole }
      }: PayloadAction<ConsumeUsersByRolePayload>
    ) => ({
      ...state,
      usersByRole
    }),
    editUserByRole: (
      state,
      { payload: { id } }: PayloadAction<EditUserByRolePayload>
    ) => ({
      ...state,
      editedUserByRoleId: id
    }),
    stopEditUserByRole: (state) => ({
      ...state,
      editedUserByRoleId: null
    }),
    deleteUserByRole: (
      state,
      { payload: { id } }: PayloadAction<EditUserByRolePayload>
    ) => ({
      ...state,
      deletedUserId: id
    }),
    stopDeleteUserByRole: (state) => ({
      ...state,
      deletedUserId: null
    }),
    setSearchText: (
      state,
      { payload: { text } }: PayloadAction<SetSearchTextPayload>
    ) => {
      state.searchText = text
    }
  }
})
