import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UsersByRole } from 'components/users/UsersByRole'
import { PageTitle } from 'components/common/PageTitle'
import { LoginHistoryTotalStats } from 'routes/Dashboard/LoginHistoryTotalStats'
import { LoginHistoryDayRangeStats } from 'routes/Dashboard/LoginHistoryDayRangeStats'
import { fetchUsersByRole } from 'redux/actions/users'
import { fetchLoginHistory } from 'redux/actions/login-history'
import { getSelectedSystemId } from 'redux/selectors/systems'


export const Dashboard: React.FC = () => {
  const dispatch = useDispatch()
  const selectedSystemId = useSelector(getSelectedSystemId)

  useEffect(() => {
    if (selectedSystemId) {
      dispatch(fetchUsersByRole())
      dispatch(fetchLoginHistory())
    }
  }, [selectedSystemId, dispatch])

  return (
    <>
      <PageTitle text="Dashboard"/>
      <LoginHistoryTotalStats/>
      <LoginHistoryDayRangeStats/>
      <UsersByRole/>
    </>
  )
}