import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Select } from 'antd'
import {
  getSelectedSystemId,
  getSystems
} from 'redux/selectors/systems'
import { setSelectedSystem } from 'redux/actions/systems'
import styles from 'components/systems/SystemSelect/styles.module.css'
import { SystemType } from 'redux/reducers/systems/types'

const { Option } = Select

export const SystemSelect: React.FC = () => {
  const dispatch = useDispatch()
  const systems = useSelector(getSystems)
  const selectedSystemId = useSelector(getSelectedSystemId)
  const selectedValue = selectedSystemId || undefined
  const renderOption = useCallback(
    ({ id, name }: SystemType) => (
      <Option
        key={`${id}-${name}`}
        value={id}
      >
        {name}
      </Option>
    ),
    []
  )

  const handleSelect = useCallback(
    (systemId: number) => dispatch(setSelectedSystem({ systemId })),
    [dispatch]
  )

  return (
    <Select
      value={selectedValue}
      className={styles.wrapper}
      disabled={!systems}
      onSelect={handleSelect}
    >
      {systems && systems.map(renderOption)}
    </Select>
  )
}
