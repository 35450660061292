export enum PermissionOperation {
  Get = 'get',
  Put = 'put',
  Post = 'post',
  Delete = 'delete',
}

export interface PermissionOperationItem {
  id: number
  name: PermissionOperation
}

export interface PermissionOperationsStateType {
  items: PermissionOperationItem[]
}