export enum HTTPHeadersType {
  ContentType = 'Content-Type'
}

export enum HttpContentTypeHeaderValues {
  ApplicationJSON = 'application/json'
}

type HTTPContentTypeHeader = Record<HTTPHeadersType.ContentType, HttpContentTypeHeaderValues>

export type HTTPHeaders = HTTPContentTypeHeader

export type FetchParams = Record<string, any>
export type FetchBody = Record<string, any>

export enum ApiRouteNames {
  Account = 'account',
  Roles = 'roles',
  Systems = 'systems',
  Users = 'users',
  AddUserByRole = 'addUserByRole',
  EditUserByRole = 'editUserByRole',
  DeleteUserByRole = 'deleteUserByRole',
  UsersByRole = 'usersByRole',
  FetchPermissions = 'fetchPermissions',
  AddPermission = 'addPermission',
  FetchPermissionRequests = 'fetchPermissionRequests',
  FetchPermissionOperations = 'fetchPermissionOperations',
  UpdatePermissionRequests = 'updatePermissionRequests',
  LoginHistory = 'loginHistory'
}

export enum ApiRoutePaths {
  Account = '/account',
  Roles = '/roles',
  Systems = '/systems',
  Users = '/users',
  UsersByRole = '/users-by-role',
  Permissions = '/permissions',
  PermissionRequests = '/user-role-request',
  PermissionOperations = '/operations',
  LoginHistory = '/login-history'
}

export interface FetchArgsType {
  body?: FetchBody
  urlParams?: FetchParams
  queryParams?: FetchParams
}

export interface ReplaceUrlArgsType {
  url: string
  urlParams?: FetchParams
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface ApiRoute {
  url: ApiRoutePaths
  method: HTTPMethods,
  headers: HTTPHeaders
}

export type ApiRoutesType = Record<string, ApiRoute>

export type WrappedApiCallback = (args?: FetchArgsType) => Promise<any>