import React from 'react'
import { PageTitle } from 'components/common/PageTitle'
import { UserPermissionRequests } from 'components/permission-requests/PermissionRequests'

export const PermissionRequestsPage: React.FC = () => {
  return (
    <div>
      <PageTitle text="Permission Request"/>
      <UserPermissionRequests/>
    </div>
  )
}