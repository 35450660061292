import React, { useMemo } from 'react'
import { Routes } from 'routes/constants'
import {
  DashboardFilled,
  ProfileFilled,
  LockFilled,
  UnorderedListOutlined
} from '@ant-design/icons'
import { NavLink as RouterNavLink } from 'react-router-dom'
import styles from 'components/layout/LeftSide/NavLink/styles.module.css'

export interface Props {
  to: Routes
  label: string
}

export const NavLink: React.FC<Props> = ({ to, label }) => {
  const icon = useMemo(() => {
    if (to === Routes.Index) {
      return <DashboardFilled className={styles.icon} />
    }

    if (to === Routes.Profile) {
      return <ProfileFilled className={styles.icon} />
    }

    if (to === Routes.Permissions) {
      return <LockFilled className={styles.icon} />
    }

    if (to === Routes.PermissionRequests) {
      return <UnorderedListOutlined className={styles.icon} />
    }
  }, [to])

  return (
    <RouterNavLink
      end
      to={to}
      className={({ isActive }) =>
        `${styles.wrapper} ${isActive ? styles.selected : ''}`
      }
    >
      {icon}
      {label}
    </RouterNavLink>
  )
}
