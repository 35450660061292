import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Avatar } from 'antd'
import { UserOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from 'components/account/AccountAvatar/styles.module.css'

export interface Props {
  size?: number
  className?: string
  isLoading?: boolean
}

export const AccountAvatar: React.FC<Props> = React.memo(({
  size,
  className,
  isLoading
}) => {
  const AvatarIcon = useMemo(() => isLoading
    ? <LoadingOutlined />
    : <UserOutlined/>, [isLoading])

  return (
    <Avatar
      size={size}
      icon={AvatarIcon}
      className={classNames(styles.wrapper, className)}
    />
  )
})