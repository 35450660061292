import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  AutoSizer,
  Table as BaseTable,
  Column,
  Index,
  SortDirectionType,
  TableCellDataGetter,
  TableCellRenderer,
  TableHeaderProps
} from 'react-virtualized'
import styles from 'components/common/Table/styles.module.css'
import { orderBy } from 'lodash'
import { GridCell } from 'components/common/Table/GridCell'

export interface TableColumnType {
  id: string
  dataKey: string
  label?: string
  width?: number
  sortDirections?: SortDirectionType[]
}

export interface GridTableHeaderProps extends TableHeaderProps {
  sortDirections?: SortDirectionType[]
}

export type LabelFormatterProps = {
  dataKey: string
  label?: string
}

export interface Props {
  rows: any[]
  height?: number
  headerHeight?: number
  rowHeight?: number
  columns: TableColumnType[]
  cellDataGetter?: TableCellDataGetter
  cellRenderer?: TableCellRenderer
  defaultSortDirections?: SortDirectionType
  defaultSortBy?: string
  labelFormatter?: (props: LabelFormatterProps) => string
}

const DEFAULT_HEADER_HEIGHT = 40
const DEFAULT_ROW_HEIGHT = 50

export const Table: React.FC<Props> = ({
  rows,
  height,
  columns,
  cellDataGetter,
  cellRenderer,
  labelFormatter,
  defaultSortDirections,
  defaultSortBy,
  rowHeight = DEFAULT_ROW_HEIGHT,
  headerHeight = DEFAULT_HEADER_HEIGHT
}) => {
  const [gridRows, setGridRows] = useState<TableColumnType[]>(rows)
  const [sortDirectionColumn, setSortDirectionColumn] = useState<
    SortDirectionType | undefined
  >(defaultSortDirections)

  const [sortByColumn, setSortByColumn] = useState<
    string | undefined
  >(defaultSortBy)

  const tableHeight = useMemo(
    () => height || (gridRows.length + 1) * rowHeight,
    [height, gridRows.length, rowHeight]
  )
  const rowGetter = useCallback(
    ({ index }: Index) => gridRows[index],
    [gridRows]
  )

  const handleSortChange = useCallback(
    ({ id, value }: { id?: string; value?: SortDirectionType }) => {
      setSortByColumn(id)
      setSortDirectionColumn(value)

      const orderedRows = value
        ? orderBy(rows, id, value?.toLowerCase() as any) // lower case because of orderBy arguments
        : rows

      setGridRows(orderedRows)
    },
    [rows]
  )

  useEffect(() => {
    handleSortChange({
      id: sortByColumn,
      value: sortDirectionColumn
    })
  }, [handleSortChange, sortByColumn, sortDirectionColumn])

  const headerRenderer = useCallback(
    ({
      label,
      sortBy,
      dataKey,
      sortDirections
    }: GridTableHeaderProps) => {
      const sortDirection =
        dataKey === sortBy ? sortDirectionColumn : undefined

      return (
        <GridCell
          id={dataKey}
          isHeaderCell
          sortDirections={sortDirections}
          selectedSortDirection={sortDirection}
          onSortChange={handleSortChange}
        >
          {/** needs to be casted because of a bug in react-virtualized */}
          <>{label as React.ReactNode}</>
        </GridCell>
      )
    },
    [handleSortChange, sortDirectionColumn]
  )
  const renderHeaderColumn = useCallback(
    ({
      id,
      label,
      width = 0,
      dataKey,
      sortDirections
    }: TableColumnType) => {
      const formattedLabel = labelFormatter
        ? labelFormatter({ label, dataKey })
        : label

      const headerRenderFn = (props: TableHeaderProps) =>
        headerRenderer({ ...props, sortDirections })

      return (
        //@ts-ignore
        <Column
          disableSort={!sortDirections}
          key={id}
          label={formattedLabel}
          width={width}
          maxWidth={width}
          dataKey={dataKey}
          flexGrow={1}
          className={styles.cell}
          headerClassName={styles.headerCell}
          cellDataGetter={cellDataGetter}
          cellRenderer={cellRenderer}
          headerRenderer={headerRenderFn}
        />
      )
    },
    [cellDataGetter, cellRenderer, labelFormatter, headerRenderer]
  )

  return (
    //@ts-ignore
    <AutoSizer disableHeight>
      {({ width }) => (
        //@ts-ignore
        <BaseTable
          className={styles.wrapper}
          headerHeight={headerHeight}
          width={width}
          height={tableHeight}
          tableWidth={width}
          rowHeight={rowHeight}
          rowClassName={styles.row}
          rowCount={rows.length}
          rowGetter={rowGetter}
          sortBy={sortByColumn}
          sortDirection={sortDirectionColumn}
        >
          {columns.map(renderHeaderColumn)}
        </BaseTable>
      )}
    </AutoSizer>
  )
}
