import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { SystemsInitialState } from 'redux/reducers/systems/constants'
import { ConsumeSystemDataPayload, SetSelectedSystemPayload } from 'redux/actions/systems/types'

export const systems = createSlice({
  name: ReducersType.Systems,
  initialState: SystemsInitialState,
  reducers: {
    consumeSystemsData: (
      state,
      {
        payload: {
          systems
        }
      }: PayloadAction<ConsumeSystemDataPayload>) => {
      const [firstSystem] = systems
      const { id } = firstSystem

      return {
        ...state,
        systems,
        selectedSystemId: id
      }
    },
    setSelectedSystem: (
      state, {
        payload: {
          systemId
        }
      }: PayloadAction<SetSelectedSystemPayload>) => ({
      ...state,
      selectedSystemId: systemId
    })
  }
})
