import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducersType } from 'redux/reducers/types'
import { PermissionRequestsInitialState } from 'redux/reducers/permission-requests/constants'
import { PermissionRequestsStateType } from 'redux/reducers/permission-requests/types'
import { ConsumePermissionRequestsPayload } from 'redux/actions/permission-requests/types'

export const permissionRequests = createSlice({
  name: ReducersType.PermissionRequests,
  initialState: PermissionRequestsInitialState,
  reducers: {
    consumeRequests: (state: PermissionRequestsStateType, {
      payload: { items }
    }: PayloadAction<ConsumePermissionRequestsPayload>) => ({
      ...state,
      items
    }),
    setRequestsStatus: (state: PermissionRequestsStateType, {
      payload: { items }
    }: PayloadAction<ConsumePermissionRequestsPayload>) => ({
      ...state,
      items: state.items.map(item => {
        const { id } = item
        const updatedItem = items.find(({ id: requestId }) => id === requestId)

        if (updatedItem) {
          const { status } = updatedItem

          return {
            ...item,
            status
          }
        }

        return item
      })
    })
  }
})
