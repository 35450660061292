import { oktaAuth } from 'utils/okta/okta-auth'
import { Token, Tokens } from '@okta/okta-auth-js'

export const checkOktaSession = async () => await oktaAuth.session.exists()

export const requestTokens = async () => await oktaAuth.token.getWithoutPrompt()

export const setTokensInStorage = (tokens: Tokens) => oktaAuth.tokenManager.setTokens(tokens)

export const getTokensFromStorage = async () => oktaAuth.tokenManager.getTokens()

export const hasTokenExpired = (token: Token) => !!token && oktaAuth.tokenManager.hasExpired(token)

export const getUserInfo = () => oktaAuth.token.getUserInfo()