import {
  FETCH_SYSTEMS_DATA,
  CONSUME_SYSTEMS_DATA,
  SET_SELECTED_SYSTEM
} from 'redux/actions/systems/constants'
import { createAction } from '@reduxjs/toolkit'
import { ConsumeSystemDataPayload, SetSelectedSystemPayload } from 'redux/actions/systems/types'

export const fetchSystemsData = createAction(FETCH_SYSTEMS_DATA)

export const consumeSystemsData = createAction<ConsumeSystemDataPayload>(CONSUME_SYSTEMS_DATA)

export const setSelectedSystem = createAction<SetSelectedSystemPayload>(SET_SELECTED_SYSTEM)