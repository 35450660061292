import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'
import { RootState } from 'redux/types/store'
import { LoginHistoryItem } from 'redux/reducers/login-history/types'
import { getTime, endOfDay, subDays } from 'date-fns'
import { difference, parseInt, uniq } from 'lodash'
import { formatTimestampToDate } from 'utils/common/date'
import { mapUniqueUserLoginsByDate } from 'redux/utils/login-history'

export const getLoginHistoryItems = (
  state: RootState
): LoginHistoryItem[] => state.loginHistory.items

export const getAllLoginsByRange = createCachedSelector(
  getLoginHistoryItems,
  (state: RootState, daysBack: number) => daysBack,
  (items, daysBack) => {
    const startDate = getTime(subDays(endOfDay(new Date()), daysBack))

    return items.filter(({ createdAt }) => createdAt > startDate)
  }
)((state, daysBack) => daysBack)

export const getUniqueUserIds = (items: LoginHistoryItem[]) =>
  uniq(items.map(({ userId }) => userId))

export const getNewUsersByRange = createCachedSelector(
  getLoginHistoryItems,
  getAllLoginsByRange,
  (state: RootState, daysBack: number) => daysBack,
  (items, itemsByRange, daysBack) => {
    const endDate = getTime(subDays(endOfDay(new Date()), daysBack))
    const itemsOutRange = items.filter(
      ({ createdAt }) => createdAt < endDate
    )
    const uniqueUsersInRange = getUniqueUserIds(itemsByRange)
    const uniqueUsersOutRange = getUniqueUserIds(itemsOutRange)
    const newUsers = difference(
      uniqueUsersInRange,
      uniqueUsersOutRange
    )

    return newUsers.length
  }
)((state, daysBack) => daysBack)

export const getLoginHistoryUniqueUserLogins = createSelector(
  getAllLoginsByRange,
  (items) => {
    const userIds = items.map(({ userId }) => userId)
    const uniqueIds = uniq(userIds)

    return uniqueIds.length
  }
)

export const getLoginHistoryUniqueUserLoginsByDay = createSelector(
  getAllLoginsByRange,
  (items) => {
    const itemsByDate = mapUniqueUserLoginsByDate(items)
    const timestamps = Object.keys(itemsByDate).sort()

    return timestamps.map((ts) => ({
      name: formatTimestampToDate(parseInt(ts)),
      count: itemsByDate[ts].length
    }))
  }
)
