export enum Colors {
  white = '#ffffff',
  athensGray = '#f1f2f5',
  bulletGraphGrey = '#d8d8d8',
  silverLight = '#c0c0c0',
  bulletGraphBlue = '#00bcf4',
  dodgerBlue = '#276cff',
  shipCove = '#6b88c5',
  fiord = '#465168',
  tradewind = '#6ac4a6',
  caribbeanGreen = '#05c6b0',
  contessa = '#c46a79',
  purpleHeart = '#8225BB',
  limeade = '#44c102',
  crimson = '#ea113c',
  fireBush = '#ea8e2a',
  corn = '#eaae08',
  laser = '#c4a66a',
  pinkalicious = '#ff99ff',
  scotchBlue = '#000077',
  tooBlueToBeTrue = '#0088ff',
  spreadsheetGreen = '#007711',
  alligator = '#886600',
  experianMagenta = '#aa5397'
}
