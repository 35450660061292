import React from 'react'
import classNames from 'classnames'
import { Typography } from 'antd'
import { formatNumberCommaSeparator } from 'utils/common/other'
import styles from './styles.module.css'

const { Text } = Typography

export interface Props {
  value: number | null
  shouldRenderValueDiff?: boolean
  formatter?: (value: number) => string
  valueClassName?: string
}

export const TotalStatsItemValue: React.FC<Props> = ({
  value,
  valueClassName,
  formatter = formatNumberCommaSeparator
}) => (
  <div className={styles.wrapper}>
    <Text
      className={classNames(styles.value, valueClassName)}
    >
      {value && formatter(value)}
    </Text>
  </div>
)
