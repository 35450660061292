import React, { useCallback } from 'react'
import classNames from 'classnames'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import styles from 'components/common/Table/GridCell/SortOptions/styles.module.css'
import { OPTION_ICONS_BY_VALUE, SortOptionIcons } from './constans'
import { SortDirectionType } from 'react-virtualized'

export interface Props {
  value: SortDirectionType
  isSelected?: boolean
  onSelect: (value?: SortDirectionType) => void
}

export const SortOption: React.FC<Props> = ({
  value,
  isSelected,
  onSelect
}) => {
  const handleClick = useCallback(
    () =>
      // Toggle sorting
      onSelect(isSelected ? undefined : value),
    [value, isSelected, onSelect]
  )

  const iconType = OPTION_ICONS_BY_VALUE[value]
  const Icon =
    iconType === SortOptionIcons.CaretUp
      ? CaretUpOutlined
      : CaretDownOutlined

  return (
    <Icon
      type={OPTION_ICONS_BY_VALUE[value]}
      className={classNames(styles.icon, {
        [styles.selected]: isSelected
      })}
      onClick={handleClick}
    />
  )
}
