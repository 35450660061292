const messages = {
  'component.permission-requests.actions.label.approve': 'Approve',
  'component.permission-requests.actions.label.reject': 'Reject',
  'component.permission-requests.permissions-requests.table.title.id':
    'ID',
  'component.permission-requests.permissions-requests.table.title.email':
    'Email',
  'component.permission-requests.permissions-requests.table.title.roleName':
    'Role Name',
  'component.permission-requests.permissions-requests.table.title.system':
    'System',
  'component.permission-requests.permissions-requests.table.title.status':
    'Status',
  'component.permission-requests.permissions-requests.table.title.createdAt':
    'Created at',
  'component.permission-requests.permissions-requests.table.title.updatedAt':
    'Updated at',
  'component.permission-requests.permissions-requests.table.title.actions':
    'Actions'
}

export default messages
