import React, { useCallback } from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'
import { SortDirectionType } from 'react-virtualized'
import { SortOptions } from './SortOptions'

interface Props {
  id: string
  children: React.ReactNode
  className?: string
  sortDirections?: SortDirectionType[]
  selectedSortDirection?: SortDirectionType
  isHeaderCell?: boolean
  isFixedRowCell?: boolean
  isFixedColumnCell?: boolean
  onSortChange?: ({
    id,
    value
  }: {
    id: string
    value?: SortDirectionType
  }) => void
}

export const GridCell: React.FC<Props> = ({
  id,
  children,
  className,
  sortDirections,
  selectedSortDirection,
  isHeaderCell,
  isFixedRowCell,
  isFixedColumnCell,
  onSortChange
}) => {
  const shouldRenderSortOptions = isHeaderCell && sortDirections

  const handleSortChange = useCallback(
    (value?: SortDirectionType) =>
      onSortChange && onSortChange({ id, value }),
    [id, onSortChange]
  )

  return (
    <div
      className={classNames(
        styles.wrapper,
        {
          [styles.headerCell]: isHeaderCell,
          [styles.fixedRowCell]: isFixedRowCell,
          [styles.fixedColumnCell]: isFixedColumnCell,
          [styles.sortedColumnCell]: selectedSortDirection
        },
        className
      )}
    >
      {shouldRenderSortOptions && (
        <SortOptions
          options={sortDirections}
          value={selectedSortDirection}
          onChange={handleSortChange}
        />
      )}
      {children}
    </div>
  )
}
