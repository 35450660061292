import { Colors } from 'constants/common/other/colors'

export const DEFAULT_CHART_WIDTH = 900
export const DEFAULT_CHART_HEIGHT = 400

export const DEFAULT_LINE_STROKE_WIDTH = 1.5

export const DEFAULT_REFERENCE_LINE_STROKE_WIDTH = 2

export const DEFAULT_LINE_CHART_COLORS = [
  Colors.dodgerBlue,
  Colors.fireBush,
  Colors.corn,
  Colors.purpleHeart,
  Colors.contessa,
  Colors.shipCove,
  Colors.caribbeanGreen,
  Colors.crimson,
  Colors.fiord,
  Colors.bulletGraphBlue,
  Colors.limeade,
  Colors.scotchBlue,
  Colors.spreadsheetGreen,
  Colors.pinkalicious,
  Colors.tooBlueToBeTrue,
  Colors.alligator,
  Colors.tradewind
]

export const DEFAULT_TOOLTIP_ITEM_STYLE = {
  padding: 0,
  lineHeight: '25px'
}

export const DEFAULT_LEGEND_WRAPPER_STYLE = {
  left: '20px',
  width: '100%'
}

export const DEFAULT_SHOULD_RENDER_LINE_DOT = false
export const LINE_ACTIVE_DOT_PROPS = { r: 5 }
