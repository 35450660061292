import React, { useCallback } from 'react'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { UserAddOutlined } from '@ant-design/icons'
import { openModal } from 'redux/actions/modals'
import styles from 'components/users/AddUserButton/styles.module.css'
import { ModalsType } from 'redux/reducers/modals/types'
import { useIntl } from 'react-intl'

export const AddUserButton: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const handleClick = useCallback(
    () => dispatch(openModal({ id: ModalsType.AddUserByRole })),
    [dispatch]
  )

  return (
    <Button
      type="primary"
      className={styles.wrapper}
      onClick={handleClick}
      icon={<UserAddOutlined className={styles.icon} />}
    >
      {formatMessage({
        id: 'component.users.add-user-button.label.add-user'
      })}
    </Button>
  )
}
