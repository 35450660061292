import React from 'react'
import classNames from 'classnames'
import { Tag } from 'antd'
import { RoleNames } from 'redux/reducers/roles/types'
import styles from 'components/common/StatusBadge/styles.module.css'

export interface Props {
  status: RoleNames
}

export const StatusBadge: React.FC<Props> = React.memo(({
  status
}) => (
  <Tag className={
    classNames(styles.wrapper, styles[status.toLowerCase()])}
  >
    {status}
  </Tag>
))