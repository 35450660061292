import { RoleNames } from 'redux/reducers/roles/types'
import { SystemNames } from 'redux/reducers/systems/types'

export enum RoleRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

export interface PermissionRequestItem {
  id: number
  userId: number
  roleId: number
  email: string
  createdAt: string
  updatedAt: string
  roleName: RoleNames
  system: SystemNames
  status: RoleRequestStatus
}

export interface UpdatedPermissionRequestItem {
  id: number
  status: RoleRequestStatus
}

export interface PermissionRequestsStateType {
  items: PermissionRequestItem[]
}