import { SortDirection, SortDirectionType } from 'react-virtualized'

export enum SortOptionIcons {
  CaretUp = 'caret-up',
  CaretDown = 'caret-down'
}

export const OPTION_ICONS_BY_VALUE: Record<
  SortDirectionType,
  SortOptionIcons
> = {
  [SortDirection.ASC]: SortOptionIcons.CaretUp,
  [SortDirection.DESC]: SortOptionIcons.CaretDown
}
