import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd'
import { ModalsType } from 'redux/reducers/modals/types'
import { AddUserByRoleModal } from 'components/modals/AddUserByRole'
import { EditUserByRoleModal } from 'components/modals/EditUserByRole'
import { DeleteUserByRoleModal } from 'components/modals/DeleteUserByRole'
import { AddPermissionModal } from 'components/modals/AddPermission'
import { closeModal } from 'redux/actions/modals'
import { getOpenedModalId } from 'redux/selectors/modals'
import styles from './styles.module.css'
import { useIntl } from 'react-intl'

export const ModalsContainer: React.FC = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const openedModalId = useSelector(getOpenedModalId)
  const title =
    openedModalId &&
    formatMessage({
      id: `component.modals.modals-container.title.${openedModalId}`
    })

  const handleCloseModal = useCallback(() => {
    if (openedModalId) {
      dispatch(closeModal({ id: openedModalId }))
    }
  }, [openedModalId, dispatch])

  const renderModalContent = useCallback(() => {
    if (openedModalId === ModalsType.AddUserByRole) {
      return <AddUserByRoleModal />
    }

    if (openedModalId === ModalsType.EditUserByRole) {
      return <EditUserByRoleModal />
    }

    if (openedModalId === ModalsType.DeleteUserByRole) {
      return <DeleteUserByRoleModal />
    }

    if (openedModalId === ModalsType.AddPermission) {
      return <AddPermissionModal />
    }

    return null
  }, [openedModalId])

  return (
    <Modal
      destroyOnClose
      open={!!openedModalId}
      className={styles.modal}
      title={title}
      onCancel={handleCloseModal}
      footer={null}
    >
      {renderModalContent()}
    </Modal>
  )
}
