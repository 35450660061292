import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { Select, Typography } from 'antd'
import { OperationBadge } from 'components/common/OperationBadge'
import { getIsLoading } from 'redux/selectors/loaders'
import { getOperations } from 'redux/selectors/permission-operations'
import { fetchOperations } from 'redux/actions/permission-operations'
import styles from 'components/roles/RoleSelect/styles.module.css'
import { PermissionOperationItem } from 'redux/reducers/permission-operations/types'
import { useIntl } from 'react-intl'

const { Text } = Typography
const { Option } = Select

interface Props {
  value?: number | number[]
  mode?: 'multiple' | 'tags'
  isMultiSelect?: boolean
  onSelect: (id: number) => void
  onDeselect?: (id: number) => void
}

export const OperationsSelect: React.FC<Props> = React.memo(
  ({ value, mode, onSelect, onDeselect }) => {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const operations = useSelector(getOperations)
    const isLoading = useSelector((state: RootState) =>
      getIsLoading(state, Loaders.FetchPermissionOperations)
    )

    const renderOption = useCallback(
      ({ id, name }: PermissionOperationItem) => (
        <Option
          key={`${id}-${name}`}
          value={id}
        >
          <OperationBadge operation={name} />
        </Option>
      ),
      []
    )

    useEffect(() => {
      dispatch(fetchOperations())
    }, [dispatch])

    return (
      <div className={styles.wrapper}>
        <Text className={styles.label}>
          {formatMessage({
            id: 'component.permission-operations.label.select-operation'
          })}
        </Text>
        <Select
          mode={mode}
          value={value}
          loading={isLoading}
          disabled={isLoading}
          className={styles.select}
          onSelect={onSelect}
          onDeselect={onDeselect}
        >
          {operations && operations.map(renderOption)}
        </Select>
      </div>
    )
  }
)
