import React from 'react'
import classNames from 'classnames'
import { Tag } from 'antd'
import { PermissionOperation } from 'redux/reducers/permission-operations/types'
import styles from './styles.module.css'

export interface Props {
  operation: PermissionOperation | string
}

export const OperationBadge: React.FC<Props> = React.memo(
  ({ operation }) => (
    <Tag className={classNames(styles.wrapper, styles[operation])}>
      {operation.toUpperCase()}
    </Tag>
  )
)
