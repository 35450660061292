import { createRoot } from 'react-dom/client'
import 'antd/dist/antd.min.css'
import './index.css'
import App from 'App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from 'redux/store'
import { IntlProvider } from 'react-intl'
import messages from 'i18n/en_US'

const container = document.getElementById('root')!
const root = createRoot(container!)

root.render(
  <Provider store={store}>
    <IntlProvider
      locale="en"
      defaultLocale="en"
      messages={messages}
    >
      <App />
    </IntlProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
