import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from 'api'
import { Loaders } from 'redux/reducers/loaders/types'
import { FETCH_OPERATIONS } from 'redux/actions/permission-operations/constants'
import { startLoading, stopLoading } from 'redux/actions/loaders'
import { consumeOperations } from 'redux/actions/permission-operations'
import { handleError } from 'redux/actions/errors'

function* handleFetchOperations() {
  yield put(startLoading(Loaders.FetchPermissionOperations))

  try {
    const { data } = yield call(api.fetchPermissionOperations)

    if (data) {
      yield put(consumeOperations({ items: data }))
    }
  } catch (err: any) {
    yield put(handleError(err))
  } finally {
    yield put(stopLoading(Loaders.FetchPermissionOperations))
  }
}

export function* permissionOperations() {
  yield all([takeLatest(FETCH_OPERATIONS, handleFetchOperations)])
}
