import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Loader } from 'components/common/Loader'
import { getIsLoading } from 'redux/selectors/loaders'

interface Props {
  count: number
}

export const UserLoginCount: React.FC<Props> = ({ count }) => {
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchLoginHistory)
  )

  if (isLoading) {
    return <Loader />
  }

  return <>{count}</>
}
