import { createAction } from '@reduxjs/toolkit'
import { FETCH_LOGIN_HISTORY, CONSUME_LOGIN_HISTORY } from 'redux/actions/login-history/constants'
import { PrepareConsumeLoginHistory } from 'redux/actions/login-history/types'
import { LoginHistoryItem } from 'redux/reducers/login-history/types'

export const fetchLoginHistory = createAction(FETCH_LOGIN_HISTORY)

export const consumeLoginHistory = createAction<PrepareConsumeLoginHistory>(
  CONSUME_LOGIN_HISTORY,
  (items: LoginHistoryItem[]) => ({
    payload: { items }
  }))
