import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Outlet } from 'react-router-dom'
import { Loader } from 'components/common/Loader'
import { Header } from 'components/layout/Header'
import { LeftSide } from 'components/layout/LeftSide'
import { ModalsContainer } from 'components/modals/ModalsContainer'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from 'routes/Index/styles.module.css'

export const Index: React.FC = () => {
  const isFetchingAccountInfo = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.FetchAccountInfo)
  )

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.loading]: isFetchingAccountInfo
      })}
    >
      <Header />
      <LeftSide />
      {isFetchingAccountInfo && true ? (
        <Loader size={24} />
      ) : (
        <Outlet />
      )}
      <ModalsContainer />
    </div>
  )
}
