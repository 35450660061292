const messages = {
  'component.header.label.permissions': 'Permissions',
  'component.left-side.label.dashboard': 'Dashboard',
  'component.left-side.label.profile': 'Profile',
  'component.left-side.label.permissions': 'Permissions',
  'component.left-side.label.permission-requests':
    'Permission requests'
}

export default messages
